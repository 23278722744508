import { derived, get } from 'svelte/store';
import { localStorageWritable } from '../utils/local-storage-writable';
import { accounts } from './accounts';

const createCurrentAccountStore = () => {
  const curAcct = localStorageWritable('currentAccount', '');

  const defaultAccount = (() => {
    const $accounts = get(accounts);
    const $curAcct = get(curAcct);
    return $accounts[$curAcct || Object.keys($accounts)[0]];
  })();

  const { subscribe } = derived(
    [accounts, curAcct],
    ([$accounts, $curAcct]) => $accounts[$curAcct || Object.keys($accounts)[0]],
    defaultAccount,
  );

  return { subscribe, set: curAcct.set };
};

export const currentAccount = createCurrentAccountStore();

<script lang="ts">
  import HashPartRouter from '../../router/HashPartRouter.svelte';
  import Divider from '../../ui/Divider.svelte';
  import Icon from '../../ui/Icon.svelte';
  import Posting from './posting/Posting.svelte';
  import './settings.scss';
  import Timelines from './timelines/Timelines.svelte';

  let currentPage: string | undefined;

  const SETTINGS_PAGES = [
    {
      name: 'Posting',
      link: 'posting',
    },
    {
      name: 'Timelines',
      link: 'timelines',
    },
  ] as const satisfies readonly { name: string; link: string }[];
</script>

<div class="column">
  <a class="button button-wide-icon body-text" href="#decks">
    <Icon name="close" width={20} height={20} />
    Close Settings
  </a>

  <Divider />

  <ul class="vertical-list">
    {#each SETTINGS_PAGES as page, index}
      <!-- TODO: use a link once routing is in place for these pages -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li>
        <a
          href={`#settings/${String(page.link)}`}
          class="list-item"
          class:selected={page.link === currentPage}
        >
          <div class="selected-indicator" />
          <span class="body-text">{page.name}</span>
        </a>
      </li>
    {/each}
  </ul>
</div>

<HashPartRouter
  bind:currentPath={currentPage}
  routes={{
    default: 'posting',
    posting: Posting,
    timelines: Timelines,
  }}
/>

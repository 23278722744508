import type { AccountAuthed } from '../../auth/accounts';
import type { Notification } from '../notification';
import type { Status } from '../status';

export type TimelineStream =
  | 'user'
  | 'public'
  | 'public:local'
  | 'hashtag'
  | 'hashtag:local'
  | 'list'
  | 'direct';

interface StreamingEventCallbacks {
  onUpdate: (status: Status) => void;
  onNotification: (notification: Notification) => void;
  onDelete: (statusId: string) => void;
  onFiltersChanged: () => void;
}

interface WebSocketEventCallbacks {
  onOpen: (event: Event) => void;
  onClose: (event: CloseEvent) => void;
  onError: (event: Event) => void;
}

interface StreamingEvent {
  event: 'update' | 'notification' | 'delete' | 'filters_changed';
  stream: TimelineStream[];
  payload: string;
}

/** Subscribe to updates from the timeline streaming API. */
export const streamTimeline = (
  account: AccountAuthed,
  stream: TimelineStream,
  {
    onUpdate,
    onNotification,
    onDelete,
    onFiltersChanged,
  }: Partial<StreamingEventCallbacks>,
  { onOpen, onClose, onError }: Partial<WebSocketEventCallbacks> = {},
) => {
  const wsParams = new URLSearchParams({
    access_token: account.token,
    stream,
  });

  const wsStreamingUrl = new URL(
    `/api/v1/streaming?${wsParams.toString()}`,
    account.instanceUrl,
  );
  wsStreamingUrl.protocol = 'wss:';

  const socket = new WebSocket(wsStreamingUrl);

  if (onOpen) {
    socket.addEventListener('open', onOpen);
  }

  if (onClose) {
    socket.addEventListener('close', onClose);
  }

  if (onError) {
    socket.addEventListener('error', onError);
  }

  socket.addEventListener('message', (e: { data: string }) => {
    const event = JSON.parse(e.data) as StreamingEvent;

    switch (event.event) {
      case 'update':
        if (onUpdate) {
          const status = JSON.parse(event.payload) as Status;
          onUpdate(status);
        }
        break;
      case 'notification':
        if (onNotification) {
          const notification = JSON.parse(event.payload) as Notification;
          onNotification(notification);
        }
        break;
      case 'delete':
        if (onDelete) {
          onDelete(event.payload);
        }
        break;
      case 'filters_changed':
        onFiltersChanged?.();
        break;
      default:
        console.error(
          `Unknown streaming API event type "${event.event as string}": `,
          event,
        );
    }
  });

  return () => {
    socket.close();
  };
};

<script lang="ts">
  import type { Status } from '../../../api/status';
  import ActionButton from './ActionButton.svelte';

  export let status: Status;

  $: pressed = status.favourited;
</script>

<ActionButton
  label="Favourite"
  {pressed}
  pressedColour="#feca57"
  icon="favourite"
  width={18}
  height={18}
/>

import { derived, get } from 'svelte/store';
import { localStorageWritable } from '../../utils/local-storage-writable';
import { randInt } from '../../utils/random';
import { uuidv4 } from '../../utils/uuid';
import { createColumn, type Column } from '../columns/columns';

export interface Deck {
  id: string;
  name: string;
  /** Icon to display to the left of deck name. */
  icon: string;
  /** Where to put this deck in the list of decks. */
  order: number;
  /** Columns in this deck. */
  cols: Column[];
}

/**
 * List of possible random emoji to pick for new decks.
 *
 * TODO: using unicode emoji like this is probably not consistent across systems
 */
const randomDeckEmoji = [
  '🦀',
  '☕',
  '🍞',
  '🌵',
  '🏳️‍🌈',
  '🌼',
  '🏳️‍⚧️',
  '🎵',
  '✨',
  '🔥',
  '💯',
  '🐸',
  '🐇',
  '❤️',
  '🍁',
  '🐢',
  '🐍',
  '🦎',
  '🐁',
  '🐀',
];

const createDecksStore = () => {
  const store = localStorageWritable<Record<string, Deck>>('decks', {});

  return {
    ...store,
    /**
     * Create a new default deck for the given account.
     *
     * Default deck comes with home, local, and federated timelines. Additional
     * timelines can be found in aisle 3.
     *
     * @returns ID of the newly added deck.
     */
    createDefaultDeck(handle: string) {
      const newDeckId = uuidv4();
      store.update((decks) => ({
        ...decks,
        [newDeckId]: {
          id: newDeckId,
          name: handle,
          icon: randomDeckEmoji[randInt(0, randomDeckEmoji.length - 1)],
          order: Object.entries(decks).length,
          cols: [
            createColumn('home', { size: 'medium', accountHandle: handle }),
            createColumn('local', { size: 'medium', accountHandle: handle }),
            createColumn('federated', {
              size: 'medium',
              accountHandle: handle,
            }),
          ],
        },
      }));
      return newDeckId;
    },
    /** Remove a deck. */
    removeDeck(deckId: string) {
      store.update((decks) => {
        const decksRes = Object.assign(decks, {});
        delete decksRes[deckId];
        return decksRes;
      });
    },
    /** Add a column to a deck. */
    addColumn(deckId: string, newColumn: Column) {
      store.update((decks) => ({
        ...decks,
        [deckId]: {
          ...decks[deckId],
          cols: [...decks[deckId].cols, newColumn],
        },
      }));
    },
    /** Replace a column in a deck. */
    replaceColumn(deckId: string, columnId: string, newColumn: Column) {
      store.update((decks) => ({
        ...decks,
        [deckId]: {
          ...decks[deckId],
          cols: decks[deckId].cols.map((col) =>
            (col.id === columnId ? newColumn : col)),
        },
      }));
    },
    /** Remove a column in a deck. */
    removeColumn(deckId: string, columnId: string) {
      store.update((decks) => ({
        ...decks,
        [deckId]: {
          ...decks[deckId],
          cols: decks[deckId].cols.filter(({ id }) => id !== columnId),
        },
      }));
    },
  };
};

export const decks = createDecksStore();

const createSelectedDeck = () => {
  const selectedDeckId = localStorageWritable<string>(
    'selectedDeckId',
    Object.keys(get(decks))[0],
  );

  const selectedDeck = derived<[typeof decks, typeof selectedDeckId], Deck>(
    [decks, selectedDeckId],
    ([$decks, $selectedDeckId]) => {
      if (!($selectedDeckId in $decks)) {
        selectedDeckId.set(Object.keys($decks)[0]);
        return $decks[Object.keys($decks)[0]];
      }
      return $decks[$selectedDeckId];
    },
    Object.values(get(decks))[0],
  );

  return {
    subscribe: selectedDeck.subscribe,
    setSelectedDeck(id: string) {
      selectedDeckId.set(id);
    },
    addNewColumn() {
      const newColumnId = uuidv4();
      const $selectedDeckId = get(selectedDeckId);
      decks.addColumn(
        $selectedDeckId,
        createColumn('new', {
          size: 'medium',
        }),
      );
      return newColumnId;
    },
    replaceColumn(columnId: string, newColumn: Column) {
      const $selectedDeckId = get(selectedDeckId);
      decks.replaceColumn($selectedDeckId, columnId, newColumn);
    },
    removeColumn(columnId: string) {
      const $selectedDeckId = get(selectedDeckId);
      decks.removeColumn($selectedDeckId, columnId);
    },
  };
};

export const selectedDeck = createSelectedDeck();

import type { AccountAuthed } from '../../auth/accounts';
import { authedFetch } from '../../utils/authed-fetch';
import { buildURLSearchParams } from '../../utils/url-search-params';
import type { Status } from '../status';

export interface GetBookmarksArgs {
  /** Return results immediately newer than id. */
  minId?: string;
  /** Return results older than id. */
  maxId?: string;
  /** Return results newer than id. */
  sinceId?: string;
  /** Maximum number of results to return. Defaults to 20. */
  limit?: number;
}

const API_GET_BOOKMARKS = '/api/v1/bookmarks';

/** Get {@link Status}es the user has bookmarked. */
export const getBookmarks = async (
  account: AccountAuthed,
  args: GetBookmarksArgs,
) => {
  const params = buildURLSearchParams(args);

  const url = new URL(
    `${API_GET_BOOKMARKS}?${params.toString()}`,
    account.instanceUrl,
  );

  const res = await authedFetch<Status[]>(account, url.toString());

  // Get next bookmarks ID
  let nextMaxId;
  const link = res.headers.get('link');
  if (link) {
    const parts = link.split(';');
    const relNextIdx = parts.findIndex((part) => part.includes('rel="next"'));
    if (relNextIdx > -1) {
      const linkPart = parts[relNextIdx - 1];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [, nextMaxId] = linkPart.match(/max_id=(\d+)/)!;
    }
  }

  return {
    statuses: await res.json(),
    nextMaxId,
  };
};

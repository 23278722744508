import bookmark from './bookmark.svg';
import boost from './boost.svg';
import close from './close.svg';
import dragHandle from './drag-handle.svg';
import eye from './eye.svg';
import favourite from './favourite.svg';
import globe from './globe.svg';
import lock from './lock.svg';
import mention from './mention.svg';
import plus from './plus.svg';
import settings from './settings.svg';
import success from './success.svg';
import unlock from './unlock.svg';

export default {
  bookmark,
  boost,
  close,
  dragHandle,
  eye,
  favourite,
  globe,
  lock,
  mention,
  plus,
  settings,
  success,
  unlock,
};

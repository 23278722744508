import { getContext, setContext } from 'svelte';
import { createEditorStore, type EditorStore } from './editor-store';

const EDITOR_CONTEXT_KEY = 'editor';

export const setEditorContext = (defaultValues?: Parameters<typeof createEditorStore>[0]) => {
  const editorStore = createEditorStore(defaultValues);
  setContext(EDITOR_CONTEXT_KEY, editorStore);
  return editorStore;
};

export const getEditorContext = () =>
  getContext<EditorStore>(EDITOR_CONTEXT_KEY);

import type { Action } from 'svelte/action';

/**
 * Svelte action that calls the given callback whhen there's a click outside of
 * the element.
 */
export const clickOutside: Action = (node, callback: () => void) => {
  const handler = (e: MouseEvent) => {
    const {
      top, right, bottom, left,
    } = node.getBoundingClientRect();
    const { clientX: x, clientY: y } = e;
    if (y < top || x > right || y > bottom || x < left) {
      callback();
    }
  };

  node.addEventListener('mouseup', handler);

  return {
    destroy() {
      window.removeEventListener('mouseup', handler);
    },
  };
};

import { uuidv4 } from '../../utils/uuid';
import type { TimelineSize } from '../timeline';

interface BaseColumn<T extends string> {
  type: T;
  id: string;
  size: TimelineSize;
}

/** For timelines that display content specific to an authenticated account. */
interface WithAccount {
  /** Handle for this column's account. */
  accountHandle: string;
}

/** New/empty column that the user can customize. */
type ColumnNew = BaseColumn<'new'>;

/** Home timeline column. */
export type ColumnHome = BaseColumn<'home'> & WithAccount;

/** Local timeline column. */
export type ColumnLocal = BaseColumn<'local'> & WithAccount;

/** Federated timeline column. */
export type ColumnFederated = BaseColumn<'federated'> & WithAccount;

/** Bookmarks column. */
export type ColumnBookmarks = BaseColumn<'bookmarks'> & WithAccount;

/** External instance timeline column. */
export type ColumnExternal = BaseColumn<'external'> & { instanceUrl: string };

export type Column =
  | ColumnNew
  | ColumnHome
  | ColumnLocal
  | ColumnFederated
  | ColumnBookmarks
  | ColumnExternal;

export const createColumn = <T extends Column['type']>(
  type: T,
  props: Omit<Extract<Column, { type: T }>, 'id' | 'type'> & { id?: string },
) =>
    ({
      id: uuidv4(),
      type,
      ...props,
    } as Extract<Column, { type: T }>);

<script lang="ts">
  import { setEditorContext } from './editor-context';
  import EditorActions from './EditorActions.svelte';
  import EditorTextarea from './EditorTextarea.svelte';

  const editor = setEditorContext();
  $: ({ postContents, maxChars } = $editor);
</script>

<form on:submit|preventDefault={editor.postStatus}>
  <EditorTextarea />
  <EditorActions />

  <div class="post-controls">
    <p
      class="char-count"
      class:char-count-max={postContents.length >= maxChars}
    >
      {postContents.length}/{maxChars}
    </p>
    <input
      type="submit"
      value="Post"
      class="button"
      disabled={postContents.length === 0}
    />
  </div>
</form>

<style lang="scss">
  form {
    width: calc(100% - (2 * var(--timeline-h-padding)));
    margin-top: var(--timeline-h-padding);

    display: flex;
    flex-direction: column;

    .post-controls {
      display: flex;

      margin-top: 12px;

      .char-count {
        margin: 0;

        &-max {
          font-weight: 600;
          color: var(--col-r-300);
        }
      }

      input[type='submit'] {
        margin-left: auto;
      }
    }
  }
</style>

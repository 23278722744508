import { wrapError } from '../utils/errors';
import type { AccountAuthed } from './accounts';

/**
 * Revokes the user's access token.
 *
 * This is broken right now, it's giving a CORS error.
 */
export const revokeToken = async ({
  username,
  instanceUrl,
  clientId,
  clientSecret,
  token,
}: AccountAuthed) => {
  const revokeUrl = new URL('/oauth/revoke', instanceUrl).toString();

  const revokeData = new FormData();
  revokeData.append('client_id', clientId);
  revokeData.append('client_secret', clientSecret);
  revokeData.append('token', token);

  try {
    await fetch(revokeUrl, {
      method: 'POST',
      body: revokeData,
      mode: 'no-cors',
    });
  } catch (err) {
    throw wrapError(err, `Error revoking token for ${username}@${instanceUrl}`);
  }
};

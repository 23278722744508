<script lang="ts">
  import type { Status } from '../../api/status';
  import Icon from '../../ui/Icon.svelte';
  import { getDisplayName } from '../../utils/handle';
  import { relativeTimestamp } from '../../utils/relative-timestamp';

  export let status: Status & { reblog: Status };

  const booster = status.account;
  const boostDate = new Date(status.created_at);
</script>

<Icon name="boost" style="margin-left: auto" />
<p class="boosted">
  <span class="boost-account">
    boosted by
    <span title={`@${booster.acct}`}>{getDisplayName(booster)}</span>
  </span>
  <span title={boostDate.toLocaleString()} class="boost-date">
    {relativeTimestamp(boostDate)}
  </span>
</p>

<style lang="scss">
  .boosted {
    margin: 0;
    margin-top: -2px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    color: var(--col-p-500);

    overflow: hidden;

    .boost-account {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .boost-date {
      font-size: 12px;
      margin-left: auto;
    }
  }
</style>

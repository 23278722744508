import { get } from 'svelte/store';
import { imageViewerStore } from './image-viewer-store';

const cloneWithPosAndSize = <T extends HTMLElement>(node: T) => {
  const rect = node.getBoundingClientRect();

  const newNode = node.cloneNode() as T;
  newNode.style.position = 'fixed';
  newNode.style.left = `${rect.left}px`;
  newNode.style.top = `${rect.top}px`;
  newNode.style.width = `${rect.width}px`;
  newNode.style.height = `${rect.height}px`;
  return newNode;
};

export const openImageViewer = (
  image: HTMLImageElement,
  caption?: HTMLElement,
) => {
  const imageViewer = get(imageViewerStore);
  // It shouldn't be possible to get here without an active image viewer state
  if (!imageViewer) {
    return;
  }

  const { thumbnail } = imageViewer;
  // If there's no thumbnail we skip the animation
  if (!thumbnail) {
    imageViewerStore.finishAnimation();
    return;
  }

  // Clone thumbnail to use it for the animation
  const animThumb = cloneWithPosAndSize(thumbnail);
  document.body.appendChild(animThumb);

  // The image viewer dialog is being shown and the image is being loaded.
  // Observe it's size so we know when we can animate the thumbnail expanding to
  // the full image size.
  new ResizeObserver((_, observer) => {
    void (async () => {
      if (
        image.naturalWidth >= thumbnail.naturalWidth &&
        image.naturalHeight >= thumbnail.naturalHeight
      ) {
        observer.disconnect();

        const imageRect = image.getBoundingClientRect();

        // Animate going to the full image position/size
        await animThumb.animate(
          [
            {
              left: `${imageRect.left}px`,
              top: `${imageRect.top}px`,
              width: `${imageRect.width}px`,
              height: `${imageRect.height}px`,
            },
          ],
          {
            duration: 200,
            fill: 'forwards',
          },
        ).finished;
        animThumb.remove();

        // Show the modal
        imageViewerStore.finishAnimation();

        // Fade in the image caption if available
        caption?.animate(
          [
            {
              transform: 'translateY(100%)',
              opacity: 0,
            },
            {
              transform: 'translateY(0)',
              opacity: 1,
            },
          ],
          { duration: 250, easing: 'ease' },
        );
      }
    })();
  }).observe(image);
};

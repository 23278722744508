import type {
  Column,
  ColumnBookmarks,
  ColumnExternal,
  ColumnFederated,
  ColumnHome,
  ColumnLocal,
} from './columns';

const TIMELINE_COLUMN_TYPES: Column['type'][] = [
  'home',
  'local',
  'federated',
  'bookmarks',
  'external',
];

export type TimelineColumn =
  | ColumnHome
  | ColumnLocal
  | ColumnFederated
  | ColumnBookmarks
  | ColumnExternal;

export const isTimelineColumn = (col: Column): col is TimelineColumn =>
  TIMELINE_COLUMN_TYPES.includes(col.type);

<script lang="ts">
  export let cwText: string;
  export let showContent: boolean;
</script>

<summary>
  <p>{cwText}</p>
  <button
    class="button button-small button-secondary"
    on:click={() => {
      showContent = !showContent;
    }}
  >
    {showContent ? 'Show Less' : 'Show More'}
  </button>
</summary>

<style>
  summary {
    list-style: none;
    pointer-events: none;
  }

  p {
    margin: 0;
    margin-top: 6px;
  }

  button {
    margin-top: 6px;
    pointer-events: all;
  }
</style>

/**
 * Generates a v4 UUID.
 *
 * {@link https://stackoverflow.com/a/2117523 Source}.
 */
/*eslint-disable*/
export const uuidv4 = (): string =>
  // @ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
/* eslint-enable */

import { sha256sum } from '../utils/sha256';

/**
 * SHA256 checksums of blocked instances.
 *
 * List imported from {@link https://mas.to/about#unavailable-content mas.to} on
 * 2022-11-09.
 */
const BLOCKED_INSTANCES = [
  'a3da30d2dfb76009289509cb3f35abd0a43b0736f0abf1ddd17c05685902a6fb',
  'd886ef4cb80c77e2638c4f1bc162d06052f0b6d70428eee116bb52d93ec4bd6b',
  '56638777979b0e88cd6cd67723f4fc152eae7ada5c266cdab45e8bb29af6be24',
  '89c918c5efd375789869f7068c6cf47389909ff281a514fa4a60d6f17bda3eb2',
  '4ed1b69def3c71d9766629329ec2705bad0a56a8fcc06a4987dc65e110e05da7',
  '8866251147a5ab3f0e99c4e47c178ee36c06804669fe9c19d8f36f5e9452815b',
  'cc1f5860fc7cd46b932c943c5e217a6648568df9c030fee9e604a3cdf9d28daa',
  'ea645a3aa6fcf9fc264bbbb1ac601f7110abf9d2ccf98a3599b46689ce5e1cf0',
  '219c11d13bced1a5ed12cf904a77b20619421fea1928327e6218ee0bd7e6a358',
  '6e6e2886f11e41609295575c079fa48f8a91a741fa8a7e7e9462997b3bec5685',
  '4e8c35d049289d25bb0a921fc1e0ab7fae2fcaf6339f83cdcbec37d9486e678d',
  '93f97f9e4124fc3cd860cf131f8ba9e135b5d7abbfb1dcace71aa122071cec30',
  'e7afdc8e99794b60044d2dc69853232fd88bf7767b08d57c06eae1dcdc2cf6d6',
  '9c7adad88887b514c96e5b4e6fb02184a339b47929c72c52ba03e08b272d9070',
  '87acc08804bcc3b72254fcae7381f2e03a6cb117d2636480bf65125f88b42da6',
  '4fe81335821bb7c5750c3241e40697f51152bf6795a92cd4d0e73280980e5c3d',
  'b03cb08d8a4e6f92bf021b39d4f3584cb55e35ad4ef13585fbbb88e4396182be',
  '07a297973ac1f7a4974fdb196878a31aca8b32d7b3fb0631849d420aa0a9d6e1',
  '0be5721be2346e0b892e6cc0db706b6b950a0d215d9d5481e851785571a89067',
  '4afdd4106fdfea8ac71a02d88cc7b6ceec757ff9fd7b1882901f9a9a8b8f25f1',
  '2c08698cf84fef82a479dd91eac09de7a383cc911df766aa3d8b496a8f874030',
  '83a5dad1f82694a6f6c9bd593f22f080dd0d0d6c8e61549582bbcb7b5beba19d',
  '84fa2303e13f19a7b0881dab91ab9e5aacf01fcdd4f1eca505fe551aa551d447',
  '4861b07e6450e2a5a862124420db543c46fcc7d280c8e0d5e07b2d388ea1e636',
  'fa1cd678ec3bfe795d28b877dcdbf909fa669dfb8f119c01c626b672e4afff36',
  '848dfaedc9e0aba0cb378f59bb745461d5e67597082a1fee4316e00c8a013da3',
  'a61fbbd27becc4c23ca5307f89703853c3538aff5839e8dab161221939a536de',
  '66deb7ec370ee6ff869c4fb64855a3dce8624603806502cde81bd70d7b4bff8d',
  'fd120d0272881c3910518a8e76cd244f2b2cfcd1b7cb0b9d999a3f037ebd76a3',
  '343b8505d931463ebcbe17ebe92dd3a5e9141a73565bf7414322dcc0e90433f8',
  'a0b7db533007ad7dec9ad5c119dd1946eda98374adc98ee4aa07d4996795db3c',
  'd1cfea1cdeefa08827822330841babe7387ce33073b72c67665c591f60956d8f',
  '23414c6b236b245c662efa05db8662743261e08cf154237d5190a6ec26887e1c',
  '7f26b4fd2707a70492f35ba6383d8f42f991474a9072f13e2c50bf5915fb9709',
  'a67a3f85d90881ae32716d0eaf5a348b53096e07ea38a20797d74824074e281b',
  'ba652c625648e15c55f48922ddb695dd22928e155ebb0b682f3950e4acbcd1f8',
  'e8bd7afb2125b7ea5e1b885fac2a657d817eec289ed870aa3d1d0acbe6aded8f',
  '7c697ddbe1e2d856db5e8c6b19f77bba60a42ef2f7f34f6e1bec5df419346715',
  '78fdcd710688fcb0b44f6748b1d8f34dc0c423ac6234763b63f46ba18b038a3b',
  '75e80dd194bc635186cf0b4a07524d7746b4b261cbd5fb15007189e4fccfbbb6',
  'ce478739d7d3627cbde08e6276b80d459a2bbf842b2698a195684f581ac8defa',
  'f0f7b4b28f4142e276d39b1fddfe621e81f72d3be86296a6323d303164f423a2',
  '56da8b2c7030ff79b9acba7a23fb6750a165a88505e0dcac85478d537d88e90f',
  '0e01f6e43d521283c18034ba874026d5b83ad02930638bcb305f24a93dae0763',
  '45cb72ec2f02c8a1500000a76b42a4825f6ee630b52cb269083c8a3c0618b832',
  '8b92d2bc4447cf5475ec0a688c1894ae5ccc720c034e46c2855ac808909172c9',
  '9ac0352d182b6a22e6838532c27af1ba0fc7e17b32438f0adbe68907405047c5',
  'a93ea0e61f58d42c5676891bcf571ed8057876ee99dc54e9431cedf31b6a4a45',
  '8c07b582ccad117d4fa14d4acca0d6977a0a737eb00e0f6a1ddf08228532e708',
  'da75242c52dfce84b86cae0ed1f9514ed9aced5016db71f535fa79c52d829ce0',
  '39b06d1f04c12e3503b6500e37ed6b27aefe78d9a5bb29b1619072b9d2488225',
  'a148b9ae905ab89d31075b91fd99a18b247ef95353325aa9ccd4568924571165',
  '292895a02595667f18851a03ec2cfad930efd41ff6f7f43f5ff1ca82441b31da',
  'd5a2981745a8c09999795e45355060aad823516355139d0f99b333a0da8ede79',
  'ff538ed5e9a94a08b9e352999dcbe096e5d08c38c2f6dc2820ed7385c87e40ac',
  '04000369b38d5a80b28cddb59bf7d7132eb36af7e804a8f066f86afdbb411525',
  'a9defb603c0ff2a974c51fcfbf46f19f40f4214f492dbd7e21f0459f283b1902',
  '19452ff02670099643197b3fec15c0d43e42a3dbbe83b0fb4d039d5f77d1932e',
  'f78337ac2a00eab976e8bba01360101916f9a4d06e940d463b52737d0b715613',
  '03862ed4050b1d6c7b914e369284d75f9cffd17288e13d6587dad5ee51599be5',
  '95896a6fa94fa220d15314771a5757484f1e531a7a0a8ce6bee5d178a1e9966a',
  '7d1958ca7311dfe74c9244b5643457bd548c8eaf8f62c418c67bd4301a06428a',
  '458e6284e52fbebf33c2be2ffef00653fab79f8b4c0ff2da5ce4d0e4653dd925',
  '0f9bb0b8035a8f5d3ec911459daffc3a62b1eeb5d2273c42d1030437693c750f',
  '3e90318967657968d49416e5ae6bead9bc0c1578264f5bd8bb67febe8a2527a6',
  '4a4a9250a8f615fd08b22c4b1af63bed81f937fe93c8dc382fd0e456934be987',
  'bfaca8bfaf9b1e13644bcbe35466669569a9f3fd2af49c6ca5243882bd682323',
  'd1c95e02c2cde60dce3d598bad93687e64dcfa507efb27b7ee3c79cdcc815134',
  '5e48f78c5402d79a630100446591f1765ba46678173a4bd76bb3bf639561bde1',
  '4f3ea385c97a95e0fda50577b3298ef3c652add8a466465b5e86a392564e5d85',
  '5c5aae17b27132ec4bde124da41c88d4eee1343a18e3b4623002f8735dbfb12c',
  '70b5e95efa310ae92a4bee5f61319c45a086130018f344dbebd9109252b7ff53',
  '24f316abb4ecb518311996750acba82970a678e00927266e09e51705267836c2',
  'eb0cb74d6eecfd29505174c0e68dc47bd72901c5867b872a380f6793bf402f9f',
  'a4aea0704dd827d41c48abfbc26e082a550ded1dc8082e36282f85b545c6f40e',
  'b451f88db3c753cdbd1a30d99c3cc47c805df5d853f4635e9837e6f1543f58b2',
  '7cfa7d014ea0a2f1fc8d18137f8eb336dcacd3df5a094cf230663fde80936123',
  '06a842a5b334d09822660618f9f62a6658c6385ccb0d08a18406bb5b63acc112',
  'aceb5ceddbc8ff17d761fb7899971c83830f856044672a04d0ff1928a82dc2cc',
  '5e8657394803e0cdad7d3e1e59fb6fd7d0440ba85963a74268710b54ea900b54',
  '15e9b52c5fa83bfbe8a8c258bacf9af2c6fc96b7c899bd3ce38e4bbcd760d913',
  'a456580c35ff79445118613dc23cabcc61f4f43ba6d777faaaa667d3de7262c4',
  'bb2c25b3014607069c14980f374c5e47fcf21789a675859b8a9307fc1989ca0c',
  'fa9be01fc02b6cc886d7dab7cd36ce13982c19f417555d1fa72ac3d7fb40d77f',
  'ab9e4f497565a60f908a4fe3696e9a5e5a3dfc11149da40738d006474e4b70e2',
  '107a196c800ddb98c502e2bdda5ac60c8e3351b5d6cd5f024835483c05343952',
  'fb9d60bae2998e383f5a292dbe93d235116503f059caa91258ebe7debd7c1646',
  '1495df111d2b77730d140d0138d5868ffa890b309869aafb987d847769446833',
  '9ec13036695359e75058e867f5fd6063fe4e3f79fbdfade86297ed447056469d',
  '4bfe1e125963fa3f9af140bb715a1de94d5d38a6e96cdf45f42eb284be1ca371',
  'ce523da5b2307836bfd2034b1969462c8f38f6a40d9559e7ea5342fac0cf68b7',
  'ac8fe7bb6c6ea2be4181879a5c95bca499883dbf6a845f9099a1cfec97d5b5b1',
  '963b22d07731c61e41e796da47ccf65743c58b7f555603e88d125d4de4cc7c54',
  'f2b2f6302c7d4204817a15a4111516493c18c567249af5c3642b1b85352fab1e',
  '70c557e8cf02d0aecdb3149bfb78e4fa8a5129a3d7a3bde2b4aa69795254f081',
  '18f6712c509a31f843837166e6db5f985b9edf20c55a2283231830a7c66f52e0',
  '84c30ffeb0e2a167cff82f692481818793934b03a3cd86cae7a3e4e4eef26059',
  '5b4890514e2dfd181a9e354c3ff3a6778ed90a96b2c3c9c9270b71640b2cc2fb',
  '761869a7e6a9ee689f4617143a0ac0510ffb9cb621cb34a65df5dcdc1cf7ec35',
];

/**
 * Check if the given instance is blocked.
 */
export const isInstanceBlocked = async (instanceUrl: string) => {
  const { host } = new URL(instanceUrl);
  const checksum = await sha256sum(host);
  return BLOCKED_INSTANCES.includes(checksum);
};

import { get, writable, type Writable } from 'svelte/store';
import type { Status, StatusVisibility } from '../../api/status';
import { postStatus as apiPostStatus } from '../../api/statuses/statuses';
import { currentAccount } from '../../auth/current-account';
import { wrapError } from '../../utils/errors';
import { settings } from '../settings/settings-store';

export interface EditorState {
  postContents: string;
  maxChars: number;
  visibility: StatusVisibility;
}

export type EditorStore = Writable<EditorState> & {
  postStatus: () => Promise<Status>;
};

export const createEditorStore = (defaultValues?: Partial<EditorState>): EditorStore => {
  const {
    postPrivacy: {
      default: defaultPostPrivacy,
      resetAfterPosting: resetPostPrivacy,
    },
  } = get(settings);

  const defaultMaxChars =
    get(currentAccount).instanceInfo.configuration.statuses.max_characters;

  const { subscribe, set, update } = writable<EditorState>({
    postContents: defaultValues?.postContents ?? '',
    maxChars: defaultValues?.maxChars ?? defaultMaxChars,
    visibility: defaultValues?.visibility ?? defaultPostPrivacy,
  });

  const postStatus = async () => {
    const { postContents, visibility } = get({ subscribe });

    const account = get(currentAccount);

    let newStatus: Status;
    try {
      newStatus = await apiPostStatus(account, {
        status: postContents,
        visibility,
      });
    } catch (err) {
      throw wrapError(err, 'Error posting status');
    }

    update(($editor) => ({
      ...$editor,
      postContents: '',
      visibility: resetPostPrivacy ? defaultPostPrivacy : $editor.visibility,
    }));

    return newStatus;
  };

  return {
    subscribe,
    set,
    update,
    postStatus,
  };
};

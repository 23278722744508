<script lang="ts">
  import { fly } from 'svelte/transition';
  import { toasts } from './toasts';
</script>

<ul class="toasts">
  {#each $toasts as toast (toast.id)}
    <li
      class={`toast toast-${String(toast.type)}`}
      transition:fly={{ y: -50 }}
      role={toast.type === 'error' ? 'alert' : 'status'}
    >
      {toast.message}
    </li>
  {/each}
</ul>

<style>
  .toasts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    list-style: none;
    pointer-events: none;
  }

  .toast {
    min-width: 100px;
    max-width: 320px;
    padding: 8px;

    text-align: center;
    border-radius: 4px;
    pointer-events: all;
  }

  .toast-info {
    background-color: #b4d6ff;
    color: #03468a;
  }

  .toast-error {
    background-color: #fbbc;
    color: #900;
  }
</style>

<script lang="ts">
  import { StatusVisibility } from '../../api/status';
  import VisibilityIcon from '../status/VisibilityIcon.svelte';
  import { getEditorContext } from './editor-context';

  const editor = getEditorContext();

  // Order for visibility to cycle through
  const VISIB_ORDER = [
    StatusVisibility.Public,
    StatusVisibility.Unlisted,
    StatusVisibility.Private,
    StatusVisibility.Direct,
  ];

  // TODO: add a proper menu for this
  const handleChangeVisibility = () => {
    const curIndex = VISIB_ORDER.findIndex((v) => v === $editor.visibility);
    let newIndex = curIndex + 1;
    // Wrap around
    if (newIndex >= VISIB_ORDER.length) {
      newIndex = 0;
    }
    $editor.visibility = VISIB_ORDER[newIndex];
  };
</script>

<!-- TODO: direct/mention icon sizing is messed up cause of scaling -->
<div>
  <button on:click={handleChangeVisibility} type="button">
    <VisibilityIcon
      visibility={$editor.visibility}
      style="color: var(--col-p-300);"
      width={20}
      height={20}
    />
  </button>
</div>

<style lang="scss">
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;

    background-color: var(--col-p-600);
    border-radius: 0 0 4px 4px;
  }

  button {
    height: 20px;
    padding: 0;
    background: none;
  }
</style>

<script lang="ts">
  import Spinner from '../ui/Spinner.svelte';
  import { startLogin } from './start-login';

  // If this login form is being shown in a modal dialog
  export let inDialog = false;

  let formError: string | undefined;
  let instanceUrl: string;
  let loading = false;

  const handleSubmit = async () => {
    if (!instanceUrl) {
      formError = 'You must enter your instance URL.';
      return;
    }

    loading = true;

    try {
      await startLogin(instanceUrl);
    } catch (err) {
      if (err instanceof Error) {
        formError = err.message;
      }
      console.error(err);
      loading = false;
    }

    // We don't set loading to false here, because if the login process started
    // correctly, the user gets redirected to their instance sign in page
  };

  const clearError = () => {
    formError = undefined;
  };
</script>

<div>
  <label for="instance">Enter your instance URL to log in:</label>
  <form on:submit|preventDefault={handleSubmit}>
    <!-- svelte-ignore a11y-autofocus -->
    <!-- Autofocus this input for when it appears in the add account dialog. -->
    <input
      type="text"
      bind:value={instanceUrl}
      on:input={clearError}
      placeholder="mastodon.social"
      name="instance"
      id="instance"
      autofocus={inDialog}
      disabled={loading}
    />
    <button
      type="submit"
      class="button button-large"
      class:loading
      disabled={loading}
    >
      {#if loading}
        <Spinner size={16} />
      {:else}
        <span>Log in ➔</span>
      {/if}
    </button>
  </form>

  <p class="error">
    {#if formError}
      {formError}
    {:else}
      <!-- Always take up at least one line to (mostly) prevent layout shift -->
      <br />
    {/if}
  </p>
</div>

<style lang="scss">
  div {
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  form {
    width: 100%;
    float: left;

    display: flex;
    gap: 12px;

    [type]:disabled {
      background-color: #929292;
      transition: all 300ms;
    }

    input[type='text'] {
      flex-grow: 1;
      height: 40px;
      padding: 0 16px;

      color: rgba(80, 80, 80, 1);
      background-color: var(--col-p-700);
      border: none;
      border-radius: 4px;
    }

    button[type='submit'] {
      width: 120px;

      &.loading {
        width: 60px;
        padding: 0;
      }

      span {
        // Don't squish the text while the button is expanding
        flex: 1 0 auto;
      }
    }
  }

  .error {
    margin: 0;
    color: var(--col-r-300);
    font-weight: bold;
  }
</style>

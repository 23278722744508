<script lang="ts">
  type $$Props = svelteHTML.HTMLProps<'div', svelteHTML.HTMLAttributes>;
</script>

<div {...$$restProps} />

<style>
  div {
    width: 100%;
    height: 1px;
    background-color: var(--col-p-500);
  }
</style>

<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';
  import icons from './icons';

  type $$Props = Omit<SVGAttributes<SVGElementTagNameMap['svg']>, 'name'> & {
    name: keyof typeof icons;
    alt?: string;
  };

  export let name: $$Props['name'];
  // eslint-disable-next-line no-undef-init
  export let alt: $$Props['alt'] = undefined;

  $: className = $$restProps.class as string | undefined;

  $: ({ svgAttributes, svgContents } = icons[name]);
</script>

<div class={className}>
  <svg
    {...svgAttributes}
    {...{
      ...$$restProps,
      class: undefined,
    }}
    aria-hidden={!alt}
    role={alt ? 'img' : undefined}
  >
    {#if alt}
      <title>{alt}</title>
    {/if}
    {@html svgContents}
  </svg>
</div>

<style>
  div {
    display: inline-flex;
  }
</style>

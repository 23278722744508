<script lang="ts">
  import Icon from '../../ui/Icon.svelte';
  import { asSpacer } from './add-column-spacer';
  import { selectedDeck } from './decks';
</script>

<button
  class="button button-secondary button-large"
  on:click={() => {
    selectedDeck.addNewColumn();
  }}
  aria-label="Add Column"
>
  <Icon name="plus" />
  <div class="divider" />
  <span>Add Column</span>
</button>

<!--
  Spacer to add some empty space to the right of the add column button.

  We also use it to scroll to the end of the columns list from elsewhere
-->
<div use:asSpacer style:flex="1 0 96px" />

<style>
  button {
    flex: 0 0 auto;

    display: flex;
    gap: 8px;

    margin: auto 0 auto 24px;
    padding: 0 10px;
  }

  .divider {
    width: 2px;
    height: calc(100% - 4px);
    background-color: var(--col-p-200);
  }
</style>

<script lang="ts">
  import NewColumn from '../new-column/NewColumn.svelte';
  import type { Column } from './columns';
  import { isTimelineColumn } from './timeline-column';
  import TimelineColumn from './TimelineColumn.svelte';

  export let col: Column;
</script>

{#if isTimelineColumn(col)}
  <TimelineColumn {col} />
{:else}
  <NewColumn id={col.id} />
{/if}

<script lang="ts">
  import type { Status } from '../../../api/status';
  import ActionButton from './ActionButton.svelte';

  export let status: Status;

  $: pressed = status.bookmarked;
</script>

<ActionButton
  label="Bookmark"
  {pressed}
  pressedColour="#f15f50"
  icon="bookmark"
  width={12}
  height={16}
/>

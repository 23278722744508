import { getContext, setContext } from 'svelte';

const HASH_PART_ROUTER_CONTEXT_KEY = 'router';

export const getHashPartRouterContext = () =>
  getContext<number | undefined>(HASH_PART_ROUTER_CONTEXT_KEY);

export const setHashPartRouterContext = () => {
  const prevPart = getHashPartRouterContext();
  return setContext(
    HASH_PART_ROUTER_CONTEXT_KEY,
    typeof prevPart !== 'undefined' ? prevPart + 1 : 0,
  );
};

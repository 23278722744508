<script lang="ts">
  import type { MouseEventHandler } from 'svelte/elements';
  import type { Attachment } from '../../api/attachments';
  import type { Status } from '../../api/status';
  import Icon from '../../ui/Icon.svelte';
  import { decodeBlurHash } from '../../utils/fast-blurhash';
  import { imageViewerStore } from '../image-viewer/image-viewer-store';

  export let status: Status;
  export let mediaAttachments: Attachment[];
  export let showSensitiveMedia: boolean;

  const blurs = new Array(mediaAttachments.length).fill(undefined);

  mediaAttachments.forEach((media, i) => {
    void (async () => {
      if (!media.blurhash) {
        return;
      }

      const width = media.meta?.small?.width ?? 256;
      const height = media.meta?.small?.height ?? 256;

      const blur = decodeBlurHash(media.blurhash, width, height);
      const imageData = new ImageData(blur, width, height);

      // Turn blur image into a blob so we can display it
      // TODO: OffscreenCanvas doesn't have amazing browser support, add fallback
      // on normal canvas (or maybe web worker)
      const canvas = new OffscreenCanvas(width, height);
      const ctx = canvas.getContext('2d');
      ctx?.putImageData(imageData, 0, 0);
      const blob = await canvas.convertToBlob();
      const objectUrl = URL.createObjectURL(blob);

      blurs[i] = objectUrl;
    })();
  });

  const handleAttachmentClicked: MouseEventHandler<HTMLAnchorElement> = (event) => {
    // If the user does a normal click, the image will be shown in the image
    // viewer. They can still open it in a new tab with a middle/right click.
    event.preventDefault();

    // Get clicked image to animate viewer opening
    const img =
      event.currentTarget.querySelector<HTMLImageElement>('img:not(.image-hider)') ?? undefined;

    const { attachmentIndex } = event.currentTarget.dataset;
    if (attachmentIndex) {
      const attachment = mediaAttachments[Number(attachmentIndex)];
      // TODO: image viewer only supports images rn
      if (attachment.type === 'image') {
        imageViewerStore.show(status, Number(attachmentIndex), img);
      }
    }
  };
</script>

<section
  aria-label="Attached media"
  class:single={mediaAttachments.length === 1}
>
  {#each mediaAttachments as { description, url, preview_url: previewUrl, id }, i (id)}
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      class:last-odd-item={mediaAttachments.length % 2 === 1 &&
        i === mediaAttachments.length - 1}
      on:click={handleAttachmentClicked}
      data-attachment-index={i}
    >
      <!--
        TODO: Below we're using opacity, pointer-events, and aria-hidden for
        hidden media blur and controls. This is because otherwise, VirtualList
        jumps around thinking that the height is changing. Will need to check
        the accessibility of this, and ideally find a cleaner fix for the
        VirtualList issue.
      -->

      <img
        src={previewUrl}
        alt={description}
        title={description}
        class="media"
        aria-hidden={!showSensitiveMedia}
      />
      {#if blurs[i]}
        <img
          src={blurs[i]}
          alt=""
          class="image-hider blur"
          style:opacity={showSensitiveMedia ? 0 : 1}
          style:pointer-events={showSensitiveMedia ? 'none' : 'all'}
        />
      {:else}
        <div
          class="image-hider no-blur"
          style:opacity={showSensitiveMedia ? 0 : 1}
          style:pointer-events={showSensitiveMedia ? 'none' : 'all'}
        />
      {/if}
    </a>
  {/each}

  <button
    class="sensitive-toggle-show"
    on:click={() => {
      showSensitiveMedia = true;
    }}
    style:opacity={showSensitiveMedia ? 0 : 1}
    aria-hidden={showSensitiveMedia}
    style:pointer-events={showSensitiveMedia ? 'none' : 'all'}
  >
    <span>Click to show sensitive content</span>
  </button>

  <button
    class="sensitive-toggle-hide"
    on:click={() => {
      showSensitiveMedia = false;
    }}
    title="Hide media"
    style:opacity={showSensitiveMedia ? 1 : 0}
    aria-hidden={!showSensitiveMedia}
    style:pointer-events={showSensitiveMedia ? 'all' : 'none'}
  >
    <Icon name="eye" alt="Hide media" />
  </button>
</section>

<style lang="scss">
  section {
    position: relative;
    margin: 12px auto 0;

    $sensitive-toggle-bg: rgba(26, 26, 50, 75%);

    .sensitive-toggle-show {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      font-size: 14px;
      background: none;
      border-radius: 8px;
      line-height: 24px;

      transition: opacity 200ms;

      span {
        max-width: 60%;
        padding: 8px 20px;

        text-align: center;
        background-color: $sensitive-toggle-bg;
        color: var(--col-p-700);
        border-radius: 8px;
      }
    }

    .sensitive-toggle-hide {
      position: absolute;
      top: 8px;
      left: 8px;

      display: flex;
      justify-content: center;
      align-items: center;
      $size: 32px;
      width: $size;
      height: $size;
      padding: 0;

      color: var(--col-p-700);
      background-color: $sensitive-toggle-bg;
      border-radius: 8px;

      transition: opacity 200ms;
    }

    $grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - ($grid-gap / 2)));
    grid-auto-rows: minmax(50px, 100px);
    gap: $grid-gap;

    &.single {
      display: flex;

      a {
        width: 100%;
        max-height: 150px;
      }
    }

    img.media {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 8px;
    }

    a {
      position: relative;
    }

    .image-hider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: block;
      border-radius: 8px;

      transition: opacity 200ms;

      &.blur {
        object-fit: cover;
      }

      &.no-blur {
        background: black;
      }
    }

    a.last-odd-item {
      grid-column: 1 / span 2;
    }
  }
</style>

<script lang="ts">
  import HashPartRouter from '../router/HashPartRouter.svelte';
  import DecksView from './DecksView.svelte';
  import DevPanel from './DevPanel.svelte';
  import ImageViewer from './image-viewer/ImageViewer.svelte';
  import Settings from './settings/Settings.svelte';
</script>

<main>
  <HashPartRouter
    routes={{
      default: DecksView,
      decks: DecksView,
      settings: Settings,
    }}
  />

  <!-- Overlays -->

  <ImageViewer />

  {#if process.env.NODE_ENV === 'development'}
    <DevPanel />
  {/if}
</main>

<style>
  main {
    width: 100%;
    height: 100%;

    display: flex;
    gap: 8px;
  }
</style>

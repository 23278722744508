import type { AccountAuthed } from '../../auth/accounts';
import { authedFetch } from '../../utils/authed-fetch';
import { buildURLSearchParams } from '../../utils/url-search-params';
import type { Status } from '../status';

export interface BaseGetTimelineArgs {
  /** Return results immediately newer than id. */
  minId?: string;
  /** Return results older than id. */
  maxId?: string;
  /** Return results newer than id. */
  sinceId?: string;
  /** Maximum number of results to return. Defaults to 20. */
  limit?: number;
}

interface GetMaybeLocalTimelineArgs extends BaseGetTimelineArgs {
  /** Return only local statuses? */
  local?: boolean;
}

const API_GET_HOME_TIMELINE = '/api/v1/timelines/home';

/** View statuses from followed users. */
export const getHomeTimeline = async (
  account: AccountAuthed,
  args: GetMaybeLocalTimelineArgs,
) => {
  const params = buildURLSearchParams(args);

  const url = new URL(
    `${API_GET_HOME_TIMELINE}?${params.toString()}`,
    account.instanceUrl,
  );

  const res = await authedFetch<Status[]>(account, url.toString());

  return res.json();
};

interface GetPublicTimelineArgs extends GetMaybeLocalTimelineArgs {
  /** Show only remote statuses? Defaults to false. */
  remote?: boolean;
  /** Show only statuses with media attached? Defaults to false. */
  only_media?: boolean;
}

const API_GET_PUBLIC_TIMELINE = '/api/v1/timelines/public';

/** View public timeline. */
export const getPublicTimeline = async (
  account: AccountAuthed,
  args: GetPublicTimelineArgs,
) => {
  const params = buildURLSearchParams(args);

  const url = new URL(
    `${API_GET_PUBLIC_TIMELINE}?${params.toString()}`,
    account.instanceUrl,
  );

  const res = await authedFetch<Status[]>(account, url.toString());

  return res.json();
};

import { get, writable } from 'svelte/store';
import type { TimelineStore } from '.';
import { getBookmarks } from '../../../api/accounts/bookmarks';
import type { Status } from '../../../api/status';
import type { BaseGetTimelineArgs } from '../../../api/timelines/timelines';
import type { AccountAuthed } from '../../../auth/accounts';
import { settings } from '../../settings/settings-store';

export const createBookmarksTimelineSore = (account: AccountAuthed): TimelineStore => {
  const store = writable<Status[]>([]);
  const { subscribe, update, set } = store;

  let nextId: string | undefined;

  const wrappedGetTimeline = (args?: BaseGetTimelineArgs) =>
    getBookmarks(account, {
      limit: get(settings).infiniteLoading.loadAtATime,
      ...args,
    });

  // TODO: handle end of bookmarks and stuff

  const initialFetch = async () => {
    const { statuses, nextMaxId } = await wrappedGetTimeline();
    nextId = nextMaxId;
    set(statuses);
  };

  const loadMore = async () => {
    const { statuses: newStatuses, nextMaxId } = await wrappedGetTimeline({
      maxId: nextId,
    });
    nextId = nextMaxId;

    update((statuses) => [...statuses, ...newStatuses]);
  };

  return {
    subscribe,
    initialFetch,
    loadMore,
  };
};

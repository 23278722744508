<script>
  import { settings } from '../settings-store';
</script>

<section>
  <h2>Infinite Loading</h2>

  <label for="infinite-loading-load-at-a-time">
    <span
      >When I reach the bottom of a timeline, load this many new posts at a
      time:</span
    >
    <input
      type="number"
      id="infinite-loading-load-at-a-time"
      bind:value={$settings.infiniteLoading.loadAtATime}
    />
  </label>
</section>

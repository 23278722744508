import type { AccountAuthed } from '../../auth/accounts';
import { authedPost } from '../../utils/authed-fetch';
import { snakify } from '../../utils/snakify';
import type { Attachment } from '../attachments';
import type { Status, StatusVisibility } from '../status';

type _docs_types = [Attachment];

export interface PostStatusArgs {
  /**
   * The text content of the status. If {@link mediaIds} is provided, this becomes
   * optional. Attaching a {@link poll}  is optional while {@link status} is
   * provided.
   *
   * @todo handle optional status case when media is attached
   */
  status: string;
  /**
   * Include {@link Attachment} IDs to be attached as media. If provided,
   * {@link status} becomes optional, and {@link poll} cannot be used.
   */
  mediaIds?: string[];
  poll?: {
    /**
     * Possible answers to the poll. If provided, {@link mediaIds} cannot be used,
     * and {@link poll["expiresIn"]} must be provided.
     */
    options: string[];
    /**
     * Duration that the poll should be open, in seconds. If provided,
     * {@link mediaIds} cannot be used, and {@link poll["options"]} must be
     * provided.
     */
    expiresIn: number;
    /** Allow multiple choices? Defaults to false. */
    multiple?: boolean;
    /** Hide vote counts until the poll ends? Defaults to false. */
    hideTotals?: boolean;
  };

  /** ID of the status being replied to, if status is a reply. */
  inReplyToId?: string;
  /** Mark status and attached media as sensitive? Defaults to false. */
  sensitive?: boolean;
  /**
   * Text to be shown as a warning or subject before the actual content.
   * Statuses are generally collapsed behind this field.
   */
  spoilerText?: string;
  /** Sets the visibility of the posted status. */
  visibility: StatusVisibility;
  /** ISO 639 language code for this status. */
  language?: string;
  /**
   * ISO 8601 Datetime at which to schedule a status. Providing this parameter
   * will cause ScheduledStatus to be returned instead of {@link Status}. Must
   * be at least 5 minutes in the future.
   */
  scheduledAt?: string;
}

const POST_STATUS_ARGS_DEFAULTS: Partial<PostStatusArgs> = {
  mediaIds: [],
  sensitive: false,
  spoilerText: '',
  language: 'en',
};

const API_POST_STATUS = '/api/v1/statuses';

/** Publish a status with the given parameters. */
export const postStatus = async (
  account: AccountAuthed,
  args: PostStatusArgs,
): Promise<Status> => {
  const params = snakify({
    ...POST_STATUS_ARGS_DEFAULTS,
    ...args,
  });

  const url = new URL(API_POST_STATUS, account.instanceUrl);

  const res = await authedPost<Status>(account, url.toString(), params);
  return res.json();
};

import { derived, writable, type Readable } from 'svelte/store';
import type { Status } from '../../api/status';

export interface ImageViewerActiveState {
  status: Status;
  attachmentIndex: number;
  thumbnail?: HTMLImageElement;
  animationFinished: boolean;
}

type ImageViewerState = undefined | ImageViewerActiveState;

const createImageViewerStore = () => {
  const { subscribe, update, set } = writable<ImageViewerState>();

  /**
   * Show the image viewer for a status's attachment.
   *
   * @param thumbnail The image element that the user clicked on to open the
   * viewer. This is used to animate the viewer opening.
   */
  const show = (
    status: Status,
    attachmentIndex: number,
    thumbnail?: HTMLImageElement,
  ) => {
    set({
      status,
      attachmentIndex,
      thumbnail,
      animationFinished: false,
    });
    // TODO: animate
  };

  /** Hide the image viewer. */
  const hide = () => {
    set(undefined);
  };

  const finishAnimation = () => {
    update((store) =>
      (store
        ? {
          ...store,
          animationFinished: true,
        }
        : store));
  };

  return {
    subscribe,
    show,
    hide,
    finishAnimation,
    set,
  };
};

export const imageViewerStore = createImageViewerStore();

export const curImage = derived(imageViewerStore, ($imageViewerStore) =>
  (typeof $imageViewerStore !== 'undefined'
    ? $imageViewerStore.status.media_attachments[
      $imageViewerStore.attachmentIndex
    ]
    : undefined));

export const next: Readable<(() => void) | undefined> = derived(
  imageViewerStore,
  ($imageViewerStore) =>
    (typeof $imageViewerStore !== 'undefined' &&
    $imageViewerStore.attachmentIndex <
      $imageViewerStore.status.media_attachments.length - 1
      ? () => {
        imageViewerStore.set({
          ...$imageViewerStore,
          attachmentIndex: $imageViewerStore.attachmentIndex + 1,
        });
      }
      : undefined),
);

export const prev: Readable<(() => void) | undefined> = derived(
  imageViewerStore,
  ($imageViewerStore) =>
    (typeof $imageViewerStore !== 'undefined' &&
    $imageViewerStore.attachmentIndex > 0
      ? () => {
        imageViewerStore.set({
          ...$imageViewerStore,
          attachmentIndex: $imageViewerStore.attachmentIndex - 1,
        });
      }
      : undefined),
);

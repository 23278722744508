<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import type { AccountAuthed } from './accounts';
  import { currentAccount } from './current-account';
  import { toasts } from '../toast';
  import Icon from '../ui/Icon.svelte';
  import Modal from '../ui/Modal.svelte';
  import Spinner from '../ui/Spinner.svelte';
  import { BLANK_IMG } from '../utils/blank-img';
  import { sleep } from '../utils/sleep';
  import { tryCompleteLogin } from './complete-login';

  let completeLoginPromiseWithMin: Promise<unknown> | undefined;
  let showModal = false;
  let accountLoaded = false;

  onMount(async () => {
    try {
      const completeLoginPromise = tryCompleteLogin();
      // If we're completing log in, use a timeout to add a minimum amount of
      // time that it'll take so we can show our pretty modal :)
      if (completeLoginPromise) {
        showModal = true;
        completeLoginPromiseWithMin = Promise.all([
          completeLoginPromise,
          sleep(2000),
        ]);

        await completeLoginPromiseWithMin;
        accountLoaded = true;

        // Show success state for a bit before closing modal
        await sleep(1000);
        showModal = false;
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(`Error completing login - ${err.message}`, {
          timeout: 7000,
        });
      }
      console.error(err);
    }
  });

  $: account = accountLoaded
    ? ($currentAccount as AccountAuthed | undefined)
    : undefined;

  $: avatarSrc = account?.avatar ?? BLANK_IMG;
</script>

{#if completeLoginPromiseWithMin}
  <Modal
    show={showModal}
    title="Adding account..."
    allowManualClose={false}
    class="complete-login-modal"
  >
    <div class="account-info">
      <img src={avatarSrc} alt="" />
      <p class="handle">
        {account?.username ?? ''}
      </p>
      <p class="instance">
        {account ? new URL(account.instanceUrl).host : ''}
      </p>
    </div>

    <div class="status-icons-container">
      {#await completeLoginPromiseWithMin}
        <div class="status-icon" out:fade={{ duration: 300 }}>
          <Spinner size={48} />
        </div>
      {:then}
        <div class="status-icon" in:fade={{ duration: 300 }}>
          <Icon name="success" style="color: var(--col-g-200)" />
        </div>
      {/await}
    </div>
  </Modal>
{/if}

<style lang="scss">
  // TODO: probably move this into shared styles
  // 🦴
  @mixin skelly-loader {
    // Animated gradient background
    background: linear-gradient(-45deg, var(--col-p-600), var(--col-p-300));
    background-size: 400% 400%;
    animation: 4s ease-in-out 0s infinite alternate both running gradient;

    @keyframes gradient {
      0% {
        background-position: 0% 25%;
      }
      50% {
        background-position: 100% 75%;
      }
      100% {
        background-position: 0% 25%;
      }
    }
  }

  :global(.complete-login-modal) {
    width: 256px !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    text-align: center;

    :global(h2) {
      margin-bottom: 16px;
    }
  }

  .account-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;

    border-radius: 8px;

    @include skelly-loader;
  }

  p {
    margin: 0;
    flex-basis: 20px;

    &:empty {
      @include skelly-loader;
      border-radius: 4px;
    }

    &.handle {
      font-size: 16px;
      line-height: 16px;

      &:empty {
        width: 100px;
      }
    }

    &.instance {
      margin-top: 4px;

      font-size: 14px;
      line-height: 14px;
      color: var(--col-p-500);

      &:empty {
        width: 75px;
      }
    }
  }

  .status-icons-container {
    width: 100%;
    // Use a grid so that we can overlay the loading and success icons and fade
    // between them
    display: grid;
    grid-template-rows: 48px;
  }

  .status-icon {
    grid-area: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

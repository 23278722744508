<script lang="ts">
  import type { Action } from 'svelte/action';
  import { toasts } from '../../toast';
  import Spinner from '../../ui/Spinner.svelte';
  import { wrapError } from '../../utils/errors';
  import type { TimelineStore } from './stores';

  export let statuses: TimelineStore;
  export let loadingMore: boolean;
  export let initialTimelineFetch: Promise<unknown>;
  export let timelineName: string;
  export let cleanHandle: string | undefined;

  const loadMore: Action<HTMLDivElement> = (loader) => {
    const observer = new IntersectionObserver(
      (entries) => {
        void (async () => {
          if (entries[0].isIntersecting) {
            loadingMore = true;

            try {
              await statuses.loadMore();
            } catch (err) {
              const errWrapped = wrapError(
                err,
                `Error loading more posts on ${timelineName} timeline${
                  cleanHandle ? ` for ${cleanHandle}` : ''
                }`,
              );
              if (errWrapped instanceof Error) {
                toasts.error(errWrapped.message);
              }
              console.error(errWrapped);
            } finally {
              loadingMore = false;
            }
          }
        })();
      },
      { threshold: 0 },
    );
    observer.observe(loader);

    return {
      destroy() {
        observer.disconnect();
      },
    };
  };
</script>

<!-- Show infinite loader after initial fetch -->
{#await initialTimelineFetch then}
  {#if !loadingMore}
    <div use:loadMore style:height="1px" />
  {:else}
    <div class="loading">
      <Spinner />
    </div>
  {/if}
{/await}

<style>
  .loading {
    display: flex;
    justify-content: center;
    padding: 16px 0;
  }
</style>

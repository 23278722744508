<script lang="ts">
  import { onMount } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { accounts } from '../auth';
  import { currentAccount } from '../auth/current-account';
  import LoginModal from '../auth/LoginModal.svelte';
  import { getHandle } from '../utils/handle';

  export let showAccountSwitcher: boolean;

  let showAddAccount = false;

  const handleSwitchAccount = (handle: string) => {
    currentAccount.set(handle);
    showAccountSwitcher = false;
  };

  const handleLogOut = (handle: string) => {
    // If logging out of current account, switch to another account
    const curHandle = getHandle($currentAccount);
    if (handle === curHandle && Object.values($accounts).length >= 2) {
      currentAccount.set(Object.keys($accounts).find((h) => h !== handle) as string);
    }

    accounts.logout(handle);
  };

  const handleAddAccount = () => {
    showAddAccount = true;
  };

  // Hide account switcher when escape key is pressed
  onMount(() => {
    const handler = (event: KeyboardEvent) => {
      if (showAccountSwitcher && event.key === 'Escape') {
        showAccountSwitcher = false;
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  });
</script>

<section
  aria-label="Account switcher"
  transition:fly={{ y: -50, duration: 250 }}
>
  <ul>
    {#each Object.entries($accounts) as [handle, account] (handle)}
      {@const url = new URL(account.instanceUrl)}
      <!--
        TODO: Not super happy with this UX, and bad structure. Maybe move the
        log out button to be overlayed on corner of account button?
        :(
      -->
      <li
        role="button"
        on:click={() => handleSwitchAccount(handle)}
        on:keypress={(e) => {
          if (['Enter', 'Space'].includes(e.code)) {
            handleSwitchAccount(handle);
          }
        }}
        tabindex="0"
      >
        <img src={account.avatar} alt="" class="profile-pic" />
        <p class="handle">@{account.username}</p>
        <p class="instance">{url.host}</p>
        <button class="logout" on:click={() => handleLogOut(handle)}>
          Log out
        </button>
      </li>
    {/each}

    <!-- TODO -->
    <li
      role="button"
      on:click={() => handleAddAccount()}
      on:keypress={(e) => {
        if (['Enter', 'Space'].includes(e.code)) {
          handleAddAccount();
        }
      }}
      tabindex="0"
    >
      <div class="add">+</div>
      <p class="handle">Add Account</p>
    </li>
  </ul>
</section>

<!-- We already handle escape key to close modal -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="backdrop"
  transition:fade={{ duration: 250 }}
  on:click={() => {
    showAccountSwitcher = false;
  }}
/>

<LoginModal bind:show={showAddAccount} />

<style lang="scss">
  section {
    position: absolute;
    width: var(--timeline-width-medium);
    height: 100%;
    z-index: 1;

    background-color: var(--col-p-200);
    border-right: solid 4px var(--col-p-100);
  }

  ul {
    margin: 0 16px;
    padding: 0;
    padding-top: 40px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    list-style: none;
  }

  li {
    padding: 12px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    background-color: transparent;
    border: none;
    border-radius: 8px;

    cursor: pointer;

    transition: all 150ms;

    &:hover {
      background-color: var(--col-p-100);
    }
  }

  .profile-pic {
    margin-bottom: 4px;
    width: 80px;
    aspect-ratio: 1 / 1;

    border-radius: 8px;
  }

  .handle {
    margin: 0;
  }

  .instance {
    margin: 0;

    font-size: 14px;
    color: var(--col-p-500);
  }

  .logout {
    margin: 0;
    padding: 0;
    height: 20px;

    font-size: 14px;
    color: var(--col-r-300);
    text-decoration: underline;
    text-decoration-color: transparent;

    border: none;
    background: none;
    cursor: pointer;

    transition: all 200ms;

    &:hover {
      text-decoration-color: unset;
    }
  }

  .add {
    width: 80px;
    height: 80px;
    margin-bottom: 4px;

    display: flex;
    justify-content: center;
    align-items: baseline;

    color: var(--col-p-700);
    font-size: 48px;

    background-color: var(--col-p-300);
    border-radius: 8px;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: calc(var(--timeline-width-medium) + 8px);
    width: 100%;
    height: 100%;
    z-index: 999;

    background-color: rgba(black, 50%);
  }
</style>

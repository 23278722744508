import type { Action } from 'svelte/action';

const SPACER_ID = 'add-column-spacer';

/**
 * Since we're using the spacer's ID to access it globally, this is
 * encapsulated in this file.
 */
export const asSpacer: Action = (node) => {
  node.setAttribute('id', SPACER_ID);
};

/** Scroll all thhe way to the right of the main columns list. */
export const scrollToEndOfColumns = () =>
  document
    .querySelector(`#${SPACER_ID}`)
    ?.scrollIntoView({ behavior: 'smooth' });

<script lang="ts">
  import { accounts } from '../../auth';
  import type { AccountAuthed } from '../../auth/accounts';
  import { getCleanHandle } from '../../utils/handle';
  import { selectedDeck } from '../decks/decks';
  import Timeline from '../timeline/Timeline.svelte';
  import TimelineHeader from '../timeline/TimelineHeader.svelte';
  import { createTimelineStore } from './create-timeline-store';
  import type { TimelineColumn } from './timeline-column';

  export let col: TimelineColumn;

  let scrollListToTop: () => void;

  const statuses = createTimelineStore(col);

  let instanceUrl: string;

  if (col.type === 'external') {
    instanceUrl = col.instanceUrl;
  }

  // Account info for authed timelines
  let accountInfo: { account: AccountAuthed; cleanHandle: string } | undefined =
    undefined;
  if ('accountHandle' in col) {
    const account = $accounts[col.accountHandle];
    const cleanHandle = getCleanHandle(account);
    accountInfo = { account, cleanHandle };
    instanceUrl = account.instanceUrl;
  }

  const timelineName = (
    {
      home: 'Home',
      local: 'Local',
      federated: 'Federated',
      bookmarks: 'Bookmarks',
      external: 'External',
    } as Record<TimelineColumn['type'], string>
  )[col.type];
</script>

<Timeline
  bind:scrollListToTop
  size={col.size}
  {statuses}
  {timelineName}
  {instanceUrl}
  cleanHandle={accountInfo?.cleanHandle}
>
  <TimelineHeader
    slot="header"
    title={timelineName}
    subtitle={accountInfo?.cleanHandle ?? instanceUrl}
    onInfoClick={scrollListToTop}
    onHeaderDblClick={() => {
      // TODO: temporary way to remove column
      selectedDeck.removeColumn(col.id);
    }}
  />
</Timeline>

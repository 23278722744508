<script lang="ts">
  import Divider from '../../ui/Divider.svelte';
  import Spinner from '../../ui/Spinner.svelte';
  import { sleep } from '../../utils/sleep';
  import { createColumn } from '../columns/columns';
  import { selectedDeck } from '../decks/decks';
  import TimelineHeader from '../timeline/TimelineHeader.svelte';

  export let id: string;
  export let currentPage: string | undefined;

  let formError: string | undefined;
  let instanceUrl: string;
  let loading = false;

  const handleSubmit = async () => {
    if (!instanceUrl) {
      formError = 'You must enter an instance URL.';
      return;
    }

    loading = true;

    // Check if we can fetch the local timeline
    let url = instanceUrl.replace('http://', 'https://');
    if (!url.startsWith('https://')) {
      url = `https://${url}`;
    }

    const timelineUrl = new URL(
      '/api/v1/timelines/public?local=true',
      url,
    ).toString();
    try {
      // Wait at least 1 second otherwise loading looks weird
      const res = (await Promise.all([fetch(timelineUrl), sleep(1000)]))[0];

      if (res.status === 200) {
        // Success, add new column
        selectedDeck.replaceColumn(
          id,
          createColumn('external', {
            id,
            size: 'medium',
            instanceUrl,
          }),
        );
        return;
      }
    } catch (err) {
      console.error(err);
    }

    loading = false;
    formError = 'Error getting instance timeline.';
  };

  const clearError = () => {
    formError = undefined;
  };
</script>

<TimelineHeader title="Add an External Column" dragHandle={false}>
  <button
    slot="subtitle"
    class="back"
    on:click={() => {
      currentPage = undefined;
    }}><span aria-hidden="true">❮ Back</span></button
  >
</TimelineHeader>

<Divider />

<form on:submit|preventDefault={handleSubmit}>
  <p><label for="instance">Enter instance URL:</label></p>

  <input
    type="text"
    bind:value={instanceUrl}
    on:input={clearError}
    placeholder="mastodon.social"
    name="instance"
    id="instance"
    disabled={loading}
  />
  <button type="submit" class="button button" class:loading disabled={loading}>
    {#if loading}
      <Spinner size={16} />
    {:else}
      <span>Submit</span>
    {/if}
  </button>

  <p class="error">
    {#if formError}
      {formError}
    {:else}
      <br />
    {/if}
  </p>
</form>

<p>
  <b>Not all instances make their local timeline publicly accessible.</b>
  <br />
  <br />
  If you get an error, it's likely that the instance requires authentication to access
  it's timeline.
</p>

<style lang="scss">
  button.back {
    flex: 1 0 auto;
    padding: 0;

    color: var(--col-p-700);
    background: none;
    border: none;
    outline: none;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 var(--timeline-h-padding);

    p {
      margin-bottom: 0;
      padding: 0;
    }

    [type]:disabled {
      background-color: #929292;
      transition: all 300ms;
    }

    input[type='text'] {
      flex-grow: 1;
      height: 40px;
      padding: 0 16px;

      color: rgba(80, 80, 80, 1);
      background-color: var(--col-p-700);
      border: none;
      border-radius: 4px;
    }

    button[type='submit'] {
      width: 80px;

      &.loading {
        width: 60px;
        padding: 0;
      }

      span {
        // Don't squish the text while the button is expanding
        flex: 1 0 auto;
      }
    }

    .error {
      margin: 0;
      padding: 0;
      color: var(--col-r-300);
      font-weight: bold;
    }
  }

  p {
    padding: 0 var(--timeline-h-padding);
  }
</style>

<script lang="ts">
  import type { ComponentProps } from 'svelte';
  import type { StatusVisibility } from '../../api/status';
  import Icon from '../../ui/Icon.svelte';

  type $$Props = { visibility: StatusVisibility } & Omit<
  ComponentProps<Icon>,
  'name' | 'alt'
  >;

  export let visibility: StatusVisibility;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  $: ({ name, alt } = (
    {
      public: {
        name: 'globe',
        alt: 'Public',
      },
      unlisted: {
        name: 'unlock',
        alt: 'Unlisted',
      },
      private: {
        name: 'lock',
        alt: 'Followers only',
      },
      direct: {
        name: 'mention',
        alt: 'Mentioned users only',
      },
    } as Record<
    StatusVisibility,
    { name: ComponentProps<Icon>['name']; alt: string }
    >
  )[visibility]);
</script>

<Icon {name} {alt} class="visibility-icon" {...$$restProps} />

<style lang="scss">
  :global(.visibility-icon) {
    filter: brightness(75%);
    transition: all 150ms;

    &:hover {
      filter: brightness(100%);
    }
  }
</style>

<script>
  import Modal from '../ui/Modal.svelte';
  import Login from './Login.svelte';

  export let show = false;
</script>

<Modal bind:show title="Add an account">
  <Login inDialog={true} />
</Modal>

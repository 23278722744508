const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Returns a relative timestamp between now and the given date.
 *
 * Assumes the given date is in the past.
 *
 * TODO: this doesn't really work lol
 */
export const relativeTimestamp = (date: Date) => {
  const now = new Date();

  const diffYears = now.getUTCFullYear() - date.getUTCFullYear();

  if (diffYears > 0) {
    return `${diffYears}y`;
  }

  const diffMonths = now.getUTCMonth() - date.getUTCMonth();
  if (diffMonths > 0) {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
  }

  const diffDays = now.getUTCDate() - date.getUTCDate();
  if (diffDays > 0) {
    if (diffDays > 6) {
      return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
    }
    return `${diffDays}d`;
  }

  const diffHours = now.getUTCHours() - date.getUTCHours();
  if (diffHours > 0) {
    return `${diffHours}h`;
  }

  const diffMins = now.getUTCMinutes() - date.getUTCMinutes();
  if (diffMins > 0) {
    return `${diffMins}m`;
  }

  const diffSecs = now.getUTCSeconds() - date.getUTCSeconds();
  if (diffSecs > 0) {
    return `${diffSecs}s`;
  }

  return 'now';
};

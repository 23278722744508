/**
 * OAuth scopes to request when authenticating.
 *
 * (this will request more scopes once more functionality is implemented)
 */
export const SCOPE = ['read', 'write:statuses'].join(' ');

/**
 * The URL that the instance will redirect the user to after they give Shokubai
 * access to their account.
 */
export const getRedirectUrl = () =>
  new URL('/', window.location.href).toString();

<script lang="ts">
  import type { Status } from '../../../api/status';
  import ActionButton from './ActionButton.svelte';

  export let status: Status;

  $: pressed = status.reblogged;
</script>

<ActionButton
  label="Boost"
  {pressed}
  pressedColour="#54a0ff"
  icon="boost"
  width={16}
  height={16}
/>

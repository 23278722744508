<script lang="ts">
  import { fade } from 'svelte/transition';
  import {
    curImage, imageViewerStore, next, prev,
  } from './image-viewer-store';
  import { openImageViewer } from './open-image-viewer';

  let dialog: HTMLDialogElement;
  // Children of the dialog, used to detect when user clicks outside dialog
  const dialogChildren: {
    image?: HTMLImageElement;
    caption?: HTMLElement;
    prevBtn?: HTMLButtonElement;
    nextBtn?: HTMLButtonElement;
  } = {
    image: undefined,
    caption: undefined,
    prevBtn: undefined,
    nextBtn: undefined,
  };

  const closeDialog = async () => {
    await dialog.animate([{ opacity: 1 }, { opacity: 0 }], { duration: 150 })
      .finished;
    imageViewerStore.hide();
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (!Object.values(dialogChildren).includes(e.target as HTMLElement)) {
      void closeDialog();
    }
  };

  // The dialog gets added to the page to position the thumbnail animation, then
  // after the animation, we make the dialog actually visible
  $: animationFinished = $imageViewerStore?.animationFinished ?? false;

  // This shows and hides the modal based on the image viewer store's state
  $: (() => {
    if (!dialog) {
      return;
    }

    if ($curImage) {
      if (dialog.open) {
        return;
      }

      dialog.showModal();

      // Schedule this so that the dialog already exists on the page before
      // animating and the click outside handler only starts listening once the
      // dialog is open
      setTimeout(() => {
        // The image should always exist at this point
        if (dialogChildren.image) {
          void openImageViewer(dialogChildren.image, dialogChildren.caption);
        }

        document.addEventListener('click', handleClickOutside);
      }, 0);
    } else {
      dialog.close();
      document.removeEventListener('click', handleClickOutside);
    }
  })();

  $: caption = $curImage?.description?.trim();
</script>

<dialog
  bind:this={dialog}
  transition:fade
  on:close={closeDialog}
  on:cancel={closeDialog}
  data-attachment-id={$curImage?.id}
  style:opacity={animationFinished ? 1 : 0}
  style:pointer-events={animationFinished ? 'unset' : 'none'}
>
  <figure>
    {#if $curImage}
      <div class="image-container">
        <button
          bind:this={dialogChildren.prevBtn}
          style:opacity={typeof $prev !== 'undefined' ? 1 : 0}
          on:click={$prev}>Prev</button
        >
        <img
          bind:this={dialogChildren.image}
          src={$curImage.url}
          alt={$curImage.description}
        />
        <button
          bind:this={dialogChildren.nextBtn}
          style:opacity={typeof $next !== 'undefined' ? 1 : 0}
          on:click={$next}>Next</button
        >
      </div>
      {#if $curImage.description}
        <figcaption
          bind:this={dialogChildren.caption}
          style:text-align={caption?.includes('\n') ? 'left' : 'center'}
          style:white-space={caption?.includes('\n')
            ? 'break-spaces'
            : 'normal'}
        >
          {caption}
        </figcaption>
      {/if}
    {/if}
  </figure>
</dialog>

<style lang="scss">
  dialog {
    // Padding around modal
    $dialog-padding: 32px;

    width: 100%;
    height: 100%;
    max-height: calc(100vh - (2 * $dialog-padding));

    margin: auto;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    overflow: hidden;

    figure {
      // Padding between image border and image
      $figure-padding: 16px;
      padding: $figure-padding;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $figure-padding;

      .image-container {
        display: flex;
        align-items: center;
      }

      img {
        max-width: 80vw;
        max-height: 80vh;

        background-color: rgba(black, 80%);
        border-radius: 8px;
        padding: 16px;
      }

      figcaption {
        flex: 1 0 auto;

        max-width: 1000px;

        color: var(--col-p-700);
        font-size: 20px;
        text-align: center;

        background-color: rgba(black, 80%);
        border-radius: 8px;
        padding: 16px;
      }
    }
  }
</style>

import { writable } from 'svelte/store';
import { uuidv4 } from '../utils/uuid';

const DEFAULT_TIMEOUT = 5000;

interface Toast {
  id: string;
  type: 'info' | 'error';
  message: string;
  timeout: number;
}

const createToasts = () => {
  const { subscribe, update } = writable<Toast[]>([]);

  const toastAdder =
    (type: Toast['type']) =>
      (
        message: string,
        options?: Partial<Omit<Toast, 'id' | 'type' | 'message'>>,
      ) =>
        update((toasts) => {
          const timeout = options?.timeout ?? DEFAULT_TIMEOUT;

          const toast: Toast = {
            id: uuidv4(),
            type,
            message,
            timeout,
          };

          setTimeout(
            () =>
              update((_toasts) => _toasts.filter(({ id }) => id !== toast.id)),
            toast.timeout,
          );

          return [toast, ...toasts];
        });

  return {
    subscribe,
    /** Displays an info toast. */
    info: toastAdder('info'),
    /** Displays an error toast. */
    error: toastAdder('error'),
  };
};

// 🍞🍞🍞🍞🍞🍞🍞🍞🍞🍞
export const toasts = createToasts();

import type { Account } from '../api/account';
import type { InstanceInfo } from '../api/instance';
import { decks, selectedDeck } from '../app/decks/decks';
import { wrapError } from '../utils/errors';
import { getHandle } from '../utils/handle';
import { accounts } from './accounts';
import { currentAccount } from './current-account';
import { getRedirectUrl, SCOPE } from './login';
import {
  clearLoginStatus,
  getLoginStatus,
  type LoginStatus,
} from './login-status';

// Maximum minutes between start of login and completing authorization
const LOGIN_TIMEOUT = 5;

/** Get the user's access token. */
const getToken = async (
  { instanceUrl, clientId, clientSecret }: LoginStatus,
  authCode: string,
) => {
  const tokenUrl = new URL('/oauth/token', instanceUrl).toString();
  const redirectUrl = getRedirectUrl();
  const res = await fetch(tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: redirectUrl,
      grant_type: 'authorization_code',
      code: authCode,
      scope: SCOPE,
    }),
  });
  const data: { access_token: string } = (await res.json()) as {
    access_token: string;
  };
  return data.access_token;
};

/** Verify the user's access token and get their account information. */
const verifyLogin = async ({ instanceUrl }: LoginStatus, token: string) => {
  const verifyUrl = new URL(
    '/api/v1/accounts/verify_credentials',
    instanceUrl,
  ).toString();
  const res = await fetch(verifyUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const account = (await res.json()) as Account;
  return account;
};

/** Get instance info like maximum post character count. */
const getInstanceInfo = async (instanceUrl: string): Promise<InstanceInfo> => {
  const infoUrl = new URL('/api/v1/instance', instanceUrl).toString();
  const res = await fetch(infoUrl);
  return (await res.json()) as InstanceInfo;
};

/**
 * Complete the login process after the user has authenticated through their
 * instance.
 */
const completeLogin = async (authCode: string) => {
  const loginStatus = getLoginStatus();
  if (!loginStatus) {
    return;
  }
  const { instanceUrl, clientId, clientSecret } = loginStatus;

  let token: string;
  try {
    token = await getToken(loginStatus, authCode);
  } catch (err) {
    throw wrapError(err, 'Error fetching auth token');
  }

  let account: Account;
  try {
    account = await verifyLogin(loginStatus, token);
  } catch (err) {
    throw wrapError(err, 'Error getting account data');
  }

  let instanceInfo: InstanceInfo;
  try {
    instanceInfo = await getInstanceInfo(loginStatus.instanceUrl);
  } catch (err) {
    throw wrapError(err, 'Error getting instance info');
  }

  const handle = getHandle({ instanceUrl, username: account.username });

  const newAccount = {
    ...account,
    instanceUrl,
    instanceInfo,
    token,
    clientId,
    clientSecret,
    loginTime: new Date().toString(),
    handle,
  };

  accounts.addAccount(newAccount);
  clearLoginStatus();

  // Switch to newly added account
  currentAccount.set(handle);
  // Add a new default deck for this account
  const newDeckId = decks.createDefaultDeck(handle);
  // Switch to the new deck
  selectedDeck.setSelectedDeck(newDeckId);

  // Clear auth code from URL
  window.history.replaceState({}, document.title, '/');
};

/**
 * Checks if we just finished the start of the login flow, and either continues
 * to the completion step or displays and errors.
 */
export const tryCompleteLogin = () => {
  const params = new URLSearchParams(window.location.search);

  // Check for errors
  const error = params.get('error');
  if (error) {
    const errorDescription = params.get('error_description');

    // Clear error from URL
    window.history.replaceState({}, document.title, '/');
    throw new Error(errorDescription ? `${error} - ${errorDescription}` : error);
  }

  const authCode = params.get('code');
  const loginStatus = getLoginStatus();

  // To continue logging in we need an auth code and active login status
  if (!authCode || !loginStatus) {
    window.history.replaceState({}, document.title, '/');
    clearLoginStatus();
    return undefined;
  }

  // Check if login expired
  const minutesSinceStart =
    (new Date().getTime() - loginStatus.time.getTime()) / (1000 * 60);
  if (minutesSinceStart > LOGIN_TIMEOUT) {
    clearLoginStatus();
    window.history.replaceState({}, document.title, '/');
    throw new Error('Login timed out, please try again.');
  }

  return completeLogin(authCode);
};

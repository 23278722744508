<script>
  import ContentWarnings from './ContentWarnings.svelte';
  import InfiniteLoading from './InfiniteLoading.svelte';
  import SensitiveMedia from './SensitiveMedia.svelte';
</script>

<section class="settings-section">
  <h1>Timelines</h1>

  <ContentWarnings />
  <SensitiveMedia />
  <InfiniteLoading />
</section>

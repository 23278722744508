<script lang="ts">
  import { currentAccount } from '../auth/current-account';
  import Divider from '../ui/Divider.svelte';
  import Icon from '../ui/Icon.svelte';
  import Decks from './decks/Decks.svelte';
  import Editor from './editor/Editor.svelte';

  export let showAccountSwitcher: boolean;

  $: ({ avatar, username } = $currentAccount);
</script>

<section aria-label="Composition column" class="column">
  <img src={avatar} alt="" class="profile-pic" />
  <p class="handle">@{username}</p>
  <button
    class="button button-secondary"
    on:click={() => {
      showAccountSwitcher = true;
    }}
    style:opacity={showAccountSwitcher ? 0 : 1}
    style:pointer-events={showAccountSwitcher ? 'none' : 'unset'}
    >Switch Account</button
  >

  <Editor />

  <Divider style="margin-top: var(--timeline-h-padding)" />

  <Decks />

  <Divider style="margin-top: auto" />
  <a class="button button-wide-icon body-text" href="#settings">
    <Icon name="settings" width={24} height={24} />
    Settings
  </a>
</section>

<style lang="scss">
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }

  .profile-pic {
    width: 25%;
    border-radius: 8px;
    aspect-ratio: 1 / 1;
  }

  .handle {
    margin: 8px auto 12px;
  }
</style>

<script>
  import { SensitiveMediaDisplay, settings } from '../settings-store';
</script>

<section>
  <h2>Sensitive Media Attachments</h2>

  <p>
    Media attached to posts (like images and videos) can be marked as sentitive
    by the author.
  </p>
  <p>
    When media is hidden, it will require an additional click before it becomes
    visible.
  </p>

  <fieldset>
    <legend>When displaying media attached to posts:</legend>

    <label for="sensitive-media-display-default">
      <input
        type="radio"
        id="sensitive-media-display-default"
        name="sensitive-media-display"
        bind:group={$settings.sensitiveMedia.display}
        value={SensitiveMediaDisplay.Default}
      />
      <span>Hide attachments that have been marked as sensitive</span>
    </label>

    <label for="sensitive-media-display-always-show">
      <input
        type="radio"
        id="sensitive-media-display-always-show"
        name="sensitive-media-display"
        bind:group={$settings.sensitiveMedia.display}
        value={SensitiveMediaDisplay.AlwaysHide}
      />
      <span>Always hide attachments</span>
    </label>

    <label for="sensitive-media-display-always-hide">
      <input
        type="radio"
        id="sensitive-media-display-always-hide"
        name="sensitive-media-display"
        bind:group={$settings.sensitiveMedia.display}
        value={SensitiveMediaDisplay.AlwaysShow}
      />
      <span>Always show attachments</span>
    </label>
  </fieldset>
</section>

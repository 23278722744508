<script lang="ts">
  import { onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { currentAccount } from '../../auth/current-account';
  import Divider from '../../ui/Divider.svelte';
  import { createColumn } from '../columns/columns';
  import { scrollToEndOfColumns } from '../decks/add-column-spacer';
  import { selectedDeck } from '../decks/decks';
  import type { TimelineSize } from '../timeline';
  import TimelineHeader from '../timeline/TimelineHeader.svelte';
  import NewExternalColumn from './NewExternalColumn.svelte';

  export let id: string;

  onMount(() => {
    scrollToEndOfColumns();
  });

  $: availableTypes = [
    {
      name: 'Home Timeline',
      description: 'Stay up to date on posts from people you follow',
      col: {
        type: 'home',
        size: 'medium',
        accountHandle: $currentAccount.handle,
      },
    },
    {
      name: 'Local Timeline',
      description: 'See posts from everyone on your instance',
      col: {
        type: 'local',
        size: 'medium',
        accountHandle: $currentAccount.handle,
      },
    },
    {
      name: 'Federated Timeline',
      description: "Find out what's happening across the Fediverse",
      col: {
        type: 'federated',
        size: 'medium',
        accountHandle: $currentAccount.handle,
      },
    },
    {
      name: 'Bookmarks',
      description: "Posts that you've saved for a rainy day",
      col: {
        type: 'bookmarks',
        size: 'medium',
        accountHandle: $currentAccount.handle,
      },
    },
    {
      name: 'External',
      description: "Peek into another instance's timeline",
      col: {
        type: 'external',
      },
    },
  ] as {
    name: string;
    description: string;
    col: {
      type: 'home' | 'local' | 'federated' | 'bookmarks' | 'external';
      size: TimelineSize;
      accountHandle: string;
    };
  }[];

  // Some column types show another step after being selected
  let currentPage: undefined | 'external' = undefined;

  const handleChooseColumnType = (col: (typeof availableTypes)[number]['col']) => {
    if (col.type === 'external') {
      currentPage = 'external';
      return;
    }

    selectedDeck.replaceColumn(id, createColumn(col.type, { id, ...col }));
  };
</script>

<section aria-label="New column" class="column" in:fly>
  {#if currentPage === 'external'}
    <NewExternalColumn {id} bind:currentPage />
  {:else}
    <TimelineHeader
      title="New Column"
      dragHandle={false}
      onHeaderDblClick={() => selectedDeck.removeColumn(id)}
    />

    <Divider />

    <p>What would you like to display here?</p>

    <ul aria-label="Available column types">
      {#each availableTypes as { name, description, col }}
        <li>
          <button
            on:click={() => {
              handleChooseColumnType(col);
            }}
          >
            <h2 class="body-text">{name}</h2>
            <h3 class="body-text">{description}</h3>
          </button>
        </li>
      {/each}
    </ul>
  {/if}
</section>

<style lang="scss">
  p {
    padding: 0 var(--timeline-h-padding);
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      button {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 6px var(--timeline-h-padding);

        background: none;
        color: var(--col-p-700);
        text-align: left;

        &:hover {
          background-color: var(--col-p-200);
        }

        h2 {
          margin: 0;
        }

        h3 {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
</style>

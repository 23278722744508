<script lang="ts">
  import { currentAccount } from '../../auth/current-account';
  import { decks, selectedDeck } from './decks';

  const handleNewDeck = () => {
    const newDeckId = decks.createDefaultDeck($currentAccount.handle);
    selectedDeck.setSelectedDeck(newDeckId);
  };

  const handleDeckSelected = (id: string) => {
    selectedDeck.setSelectedDeck(id);
  };
</script>

<section>
  <header>
    <h2 class="body-text">Decks</h2>
    <button class="button button-small button-outline" on:click={handleNewDeck}
      >New Deck</button
    >
  </header>

  <ul class="vertical-list">
    {#each Object.values($decks) as deck (deck.id)}
      {@const selected = deck.id === $selectedDeck.id}
      <li
        class="list-item"
        class:selected
        on:dblclick={() => {
          // TODO: temporary way to delete decks
          decks.removeDeck(deck.id);
        }}
      >
        <div class="selected-indicator" />
        <span class="icon" aria-hidden="true">{deck.icon}</span>
        <button class="body-text" on:click={() => handleDeckSelected(deck.id)}
          >{deck.name}</button
        >
      </li>
    {/each}
  </ul>
</section>

<style lang="scss">
  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  header {
    height: 24px;
    margin: var(--timeline-h-padding);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-weight: 500;
  }

  ul {
    li {
      .icon {
        font-size: 24px;
        line-height: 24px;
        margin-left: 4px;
      }

      // From .vertical-list styles, will merge with those at some point
      $item-height: 36px;
      $hover-transition-time: 200ms;

      button {
        width: 100%;
        height: $item-height;
        display: flex;
        align-items: center;

        color: var(--col-p-700);
        background: none;
        border: none;
        outline: none;

        transition: all $hover-transition-time;
      }
    }
  }
</style>

import { StatusVisibility } from '../../api/status';
import { localStorageWritable } from '../../utils/local-storage-writable';

/** Behaviour for blurring sensitive media. */
export enum SensitiveMediaDisplay {
  /** Sensitive media will be blurred, non-sensitive media won't be. */
  Default = 'default',
  /** Media will always be blurred by default, regardless of sensitivity. */
  AlwaysHide = 'alwaysHide',
  /** Media will never be blurred by default, even if sensitive. */
  AlwaysShow = 'alwaysShow',
}

export interface Settings {
  postPrivacy: {
    /** Default post privacy. */
    default: StatusVisibility;
    /**
     * If the post privacy setting should be reset back to the default
     * after posting.
     */
    resetAfterPosting: boolean;
  };
  postLanguage: {
    /** Default post language. */
    default: 'en';
  };
  contentWarnings: {
    /** If content warnings should always be expanded by default. */
    alwaysExpand: boolean;
  };
  sensitiveMedia: {
    /** Behaviour for blurring sensitive media. */
    display: SensitiveMediaDisplay;
  };
  infiniteLoading: {
    /**
     * If ininite loading should be enabled.
     *
     * @todo Not yet implemented.
     */
    enabled: true;
    /** Number of posts to fetch at a time from timelines. */
    loadAtATime: number;
  };
}

const DEFAULT_SETTINGS: Settings = {
  postPrivacy: {
    default: StatusVisibility.Public,
    resetAfterPosting: false,
  },
  postLanguage: {
    default: 'en',
  },
  contentWarnings: {
    alwaysExpand: false,
  },
  sensitiveMedia: {
    display: SensitiveMediaDisplay.Default,
  },
  infiniteLoading: {
    enabled: true,
    loadAtATime: 10,
  },
};

export const settings = localStorageWritable<Settings>(
  'settings',
  DEFAULT_SETTINGS,
);

import { snakify } from './snakify';

/**
 * Convert the given object into URL query parameters with URLSearchParams.
 *
 * Also converts the object keys from camelCase to snake_case.
 */
export const buildURLSearchParams = (args: object) => {
  const params = new URLSearchParams();

  const snakeArgs = snakify(args);

  for (const [key, val] of Object.entries(snakeArgs)) {
    if (typeof val === 'undefined') {
      continue;
    }

    params.append(key, String(val));
  }

  return params;
};

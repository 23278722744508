<script lang="ts">
  import Icon from '../../../ui/Icon.svelte';
  import type icons from '../../../ui/icons';

  /** Action label. */
  export let label: string;
  /** If the action is currently pressed. */
  export let pressed: boolean;
  /** Colour to use for icon in pressed state. */
  export let pressedColour: string;
  /** Icon to display for action. */
  export let icon: keyof typeof icons;
  /** Icon width. */
  export let width: number;
  /** Icon height. */
  export let height: number;
</script>

<button
  aria-label={label}
  aria-pressed={pressed}
  title={label}
  style="--pressed-colour: {pressedColour}"
>
  <Icon name={icon} {width} {height} aria-hidden="true" />
</button>

<style lang="scss">
  button {
    height: 16px;
    padding: 0;
    background: none;

    color: var(--col-p-500);

    transition: all 350ms;

    &[aria-pressed='true'] {
      color: var(--pressed-colour);
    }
  }
</style>

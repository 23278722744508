import { wrapError } from '../utils/errors';
import { isInstanceBlocked } from './blocked-instances';
import { getRedirectUrl, SCOPE } from './login';
import { setLoginStatus } from './login-status';

interface RegisterRes {
  client_id: string;
  client_secret: string;
  id: string;
  name: string;
  redirect_uri: string;
  vapid_key: string;
  website: string;
}

const registerApp = async ({
  instanceUrl,
  redirectUrl,
}: {
  instanceUrl: string;
  redirectUrl: string;
}) => {
  const registerUrl = new URL('/api/v1/apps', instanceUrl).toString();

  try {
    const res = await fetch(registerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_name: 'Shokubai',
        redirect_uris: redirectUrl,
        scopes: SCOPE,
        website: 'https://git.sr.ht/~avery/shokubai',
      }),
    });
    return (await res.json()) as RegisterRes;
  } catch (err) {
    throw wrapError(err, 'Error registering app');
  }
};

const login = ({
  instanceUrl,
  redirectUrl,
  clientId,
}: {
  instanceUrl: string;
  redirectUrl: string;
  clientId: string;
}) => {
  const loginParams = new URLSearchParams({
    client_id: clientId,
    scope: SCOPE,
    redirect_uri: redirectUrl,
    response_type: 'code',
  });
  const loginUrl = new URL(
    `/oauth/authorize?${loginParams.toString()}`,
    instanceUrl,
  );
  window.location.assign(loginUrl);
};

export const startLogin = async (_instanceUrl: string) => {
  // TODO: better processing of instance URL
  let instanceUrl = _instanceUrl.replace('http://', 'https://');
  if (!instanceUrl.startsWith('https://')) {
    instanceUrl = `https://${instanceUrl}`;
  }

  const blocked = await isInstanceBlocked(instanceUrl);
  if (blocked) {
    throw new Error('This instance is not supported.');
  }

  // TODO: check server header in response to /api/v1/instance to check server
  // type, and warn if not Mastodon

  const redirectUrl = getRedirectUrl();

  const { client_id: clientId, client_secret: clientSecret } =
    await registerApp({
      instanceUrl,
      redirectUrl,
    });

  // Save login state before we go to login page
  setLoginStatus({
    time: new Date(),
    instanceUrl,
    clientId,
    clientSecret,
  });

  login({ instanceUrl, redirectUrl, clientId });
};

<script>
  import { settings } from '../settings-store';
</script>

<section>
  <h2>Content Warnings</h2>

  <label for="expand-content-warnings">
    <input
      type="checkbox"
      id="expand-content-warnings"
      bind:checked={$settings.contentWarnings.alwaysExpand}
    />
    <span>Expand posts with content warnings by default</span>
  </label>
</section>

/**
 * Add additional context to the passed in error's message.
 *
 * @returns The passed in error with augmented message.
 */
export const wrapError = (err: unknown, message: string) => {
  if (err instanceof Error) {
    // eslint-disable-next-line no-param-reassign
    err.message = `${message} - ${err.message}`;
  }
  return err;
};

<script lang="ts">
  import { sanitize } from 'dompurify';
  import { slide } from 'svelte/transition';
  import MediaAttachments from './MediaAttachments.svelte';
  import type { Status } from '../../api/status';
  import VisibilityIcon from './VisibilityIcon.svelte';
  import { relativeTimestamp } from '../../utils/relative-timestamp';
  import ContentWarning from './ContentWarning.svelte';
  import Boosted from './Boosted.svelte';
  import { getDisplayName } from '../../utils/handle';
  import {
    statusShowContentStore,
    statusShowMediaStore,
  } from './global-status-state';
  import Divider from '../../ui/Divider.svelte';
  import StatusActions from './status-actions/StatusActions.svelte';
  import { SensitiveMediaDisplay, settings } from '../settings/settings-store';

  export let status: Status;
  export let instanceUrl: string;

  // We check if the status is a reblog in some places below, but the type isn't
  // narrowed, so we pass this down instead.
  const statusWithReblog = status as Status & { reblog: Status };

  // If this was a reblog, display the original status
  const displayStatus = status.reblog ?? status;

  const displayName = getDisplayName(displayStatus.account);
  const date = new Date(displayStatus.created_at);
  // Content is HTML, sanitize before rendering it
  const content = sanitize(displayStatus.content);
  /**
   * If the status content should be shown.
   *
   * If the status has a content warning, this defaults to false.
   */
  $: showContent = statusShowContentStore(
    instanceUrl,
    status,
    $settings.contentWarnings.alwaysExpand || !displayStatus.spoiler_text,
  );
  /**
   * If the status media should be shown.
   *
   * If the media is marked sensitive, this defaults to false.
   */
  $: showSensitiveMedia = statusShowMediaStore(
    instanceUrl,
    status,
    $settings.sensitiveMedia.display === SensitiveMediaDisplay.Default
      ? !displayStatus.sensitive
      : $settings.sensitiveMedia.display === SensitiveMediaDisplay.AlwaysShow,
  );
</script>

<section class="status" data-status-id={status.id}>
  <header>
    {#if status.reblog}
      <Boosted status={statusWithReblog} />
    {/if}
    <img src={displayStatus.account.avatar} alt="" class="profile-pic" />
    <div class="username">
      <p class="display-name" title={displayName}>{displayName}</p>
      <p class="handle">
        <span title={`@${displayStatus.account.acct}`}
          >@{displayStatus.account.acct}</span
        >
        <VisibilityIcon visibility={displayStatus.visibility} />
        <a
          class="date"
          title={date.toLocaleString()}
          href={displayStatus.url}
          target="_blank"
          rel="noreferrer">{relativeTimestamp(date)}</a
        >
      </p>
    </div>
  </header>

  {#if displayStatus.spoiler_text}
    <details bind:open={$showContent}>
      <ContentWarning
        cwText={displayStatus.spoiler_text}
        bind:showContent={$showContent}
      />
      {#if $showContent}
        <div class="status-content" in:slide|local>{@html content}</div>
      {/if}
    </details>
  {:else if $showContent}
    <div class="status-content">{@html content}</div>
  {/if}
  {#if displayStatus.media_attachments.length > 0}
    <MediaAttachments
      status={displayStatus}
      mediaAttachments={displayStatus.media_attachments}
      bind:showSensitiveMedia={$showSensitiveMedia}
    />
  {/if}

  <StatusActions {status} />
</section>

<Divider />

<style lang="scss">
  .status {
    padding: 8px var(--timeline-h-padding) 12px;
  }

  header {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(2, auto);
    column-gap: 10px;
    row-gap: 4px;
    align-items: center;
    color: var(--col-p-500);
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  .username {
    display: flex;
    flex-direction: column;

    overflow: auto;

    .display-name {
      font-weight: 500;
      color: var(--col-p-700);
    }

    .handle {
      font-size: 14px;
      color: var(--col-p-500);

      display: flex;
      align-items: center;
      gap: 6px;

      span {
        margin-right: auto;
      }
    }

    a.date {
      overflow: visible;

      color: var(--col-p-500);

      text-decoration: underline;
      text-decoration-color: transparent;

      transition: all 200ms;

      &:hover {
        text-decoration-color: unset;
      }
    }

    p,
    span {
      margin: 0;

      // Truncate long display names and handles with ellipsis
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .status-content {
    margin-top: 6px;

    color: var(--col-p-800);
    word-wrap: break-word;
  }

  :global(.status-content p) {
    margin: 12px 0;
  }

  :global(.status-content > *:first-child) {
    margin-top: 0;
  }

  :global(.status-content > *:last-child) {
    margin-bottom: 0;
  }
</style>

import { get, writable } from 'svelte/store';
import type { TimelineStore } from '.';
import type { Status } from '../../../api/status';
import { typedFetch } from '../../../utils/typed-fetch';
import { buildURLSearchParams } from '../../../utils/url-search-params';
import { settings } from '../../settings/settings-store';

export const createExternalTimelineStore = (instanceUrl: string): TimelineStore => {
  const store = writable<Status[]>([]);
  const { subscribe, set } = store;

  const wrappedGetTimeline = async (maxId?: string) => {
    const args = buildURLSearchParams({
      local: true,
      limit: get(settings).infiniteLoading.loadAtATime,
      maxId,
    });

    const url = new URL(
      `/api/v1/timelines/public?${args.toString()}`,
      `https://${instanceUrl}`,
    );

    const res = await typedFetch<Status[]>(url);
    return res.json();
  };

  const initialFetch = async () => {
    set(await wrappedGetTimeline());
  };

  const loadMore = async () => {
    const statuses = get(store);

    const oldestStatusId = statuses[statuses.length - 1].id;

    const newStatuses = await wrappedGetTimeline(oldestStatusId);

    set([...statuses, ...newStatuses]);
  };

  return {
    subscribe,
    initialFetch,
    loadMore,
  };
};

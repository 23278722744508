import { get, writable, type Writable } from 'svelte/store';

// Modified version of https://github.com/joshnuss/svelte-local-storage-store/blob/be890630395112cde6dc630f07b91617dfd68fcc/index.ts

/**
 *  A writable Svelte store that automatically syncs with localStorage.
 */
export const localStorageWritable = <T>(
  key: string,
  initialValue: T,
): Writable<T> => {
  const store = writable(initialValue, (set) => {
    const data = localStorage.getItem(key);
    if (data) {
      set(JSON.parse(data) as T);
    }

    // Update store if it's localStorage item is modified
    const handleStorage = (e: StorageEvent) => {
      if (e.key === key) {
        set(e.newValue ? (JSON.parse(e.newValue) as T) : initialValue);
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  });
  const { subscribe, set } = store;

  return {
    subscribe,
    set(val) {
      set(val);
      localStorage.setItem(key, JSON.stringify(val));
    },
    update(updater) {
      const newVal = updater(get(store));
      set(newVal);
      localStorage.setItem(key, JSON.stringify(newVal));
    },
  };
};

<script lang="ts">
  import Login from './auth/Login.svelte';
</script>

<main>
  <header>
    <h1>shokubai</h1>
    <p role="doc-subtitle">Client for Mastodon (and compatible servers).</p>
  </header>

  <section>
    <h2>Login</h2>
    <Login />
  </section>

  <section>
    <h2>Signup</h2>
    <p>
      Before using Shokubai, you must sign up on an existing Mastodon instance.
      <a href="https://joinmastodon.org/">Join Mastodon -></a>
    </p>
  </section>
</main>

<style lang="scss">
  main {
    max-width: 900px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--col-p-100);

    header {
      margin-top: 32px;

      display: flex;
      flex-direction: column;

      text-align: center;

      h1 {
        margin-bottom: 16px;
      }
    }
  }

  section {
    width: 100%;
    max-width: 500px;
    margin-top: 64px;

    display: flex;
    flex-direction: column;

    h2 {
      width: fit-content;
      margin: 0 auto;
    }
  }
</style>

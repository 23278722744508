<script lang="ts">
  import { afterUpdate, onMount } from 'svelte';
  import type { Status as IStatus } from '../../api/status';
  import { toasts } from '../../toast';
  import Divider from '../../ui/Divider.svelte';
  import VirtualList from '../../ui/VirtualList.svelte';
  import { wrapError } from '../../utils/errors';
  import { scrollToTop } from '../../utils/actions';
  import Status from '../status/Status.svelte';
  import LoadMore from './LoadMore.svelte';
  import type { TimelineStore } from './stores';
  import type { TimelineSize } from '.';

  /** Timeline size. */
  export let size: TimelineSize = 'medium';
  /** Store containing statuses for this timeline. */
  export let statuses: TimelineStore;
  /** Timeline name. */
  export let timelineName: string;
  /** URL of this timeline's instance. */
  export let instanceUrl: string;
  /** User handle for authed timeline. */
  export let cleanHandle: string | undefined;
  /** Can be bound to get a method that scrolls to the top of this timeline. */
  export let scrollListToTop: () => void;

  // Keep a reference to the initial fetch promise accessible to the component,
  // so that we know when to show the infinite loader thingy.
  let initialTimelineFetch: Promise<IStatus[]>;

  onMount(async () => {
    try {
      await statuses.initialFetch();
    } catch (err) {
      const errWrapped = wrapError(
        err,
        `Error getting ${timelineName} timeline${
          cleanHandle ? ` for ${cleanHandle}` : ''
        }`,
      );
      if (errWrapped instanceof Error) {
        toasts.error(errWrapped.message);
      }
      console.error(errWrapped);
    }
  });

  // Infinite loading
  let loadingMore = true;
  // Wait until after initial statuses render before showing loader
  let completedInitialTimelineFetch = false;
  afterUpdate(() => {
    if (!completedInitialTimelineFetch && $statuses.length > 0) {
      completedInitialTimelineFetch = true;
      loadingMore = false;
    }
  });

  // Used below to force the type to be a status
  const toStatusType = (s: unknown) => s as IStatus;
</script>

<section
  aria-label={`${timelineName} timeline for ${cleanHandle ?? instanceUrl}`}
  style:width={`var(--timeline-width-${size})`}
  class="column"
>
  <slot name="header" />

  <Divider />

  <div class="status-list" use:scrollToTop>
    <VirtualList
      items={[...$statuses, { id: 'loadMore' }]}
      let:item
      bind:scrollToTop={scrollListToTop}
    >
      {#if item.id === 'loadMore'}
        <LoadMore
          {statuses}
          bind:loadingMore
          {initialTimelineFetch}
          {timelineName}
          {cleanHandle}
        />
      {:else}
        {@const status = toStatusType(item)}
        <Status {status} {instanceUrl} />
      {/if}
    </VirtualList>
  </div>
</section>

<style lang="scss">
  section {
    display: flex;
    flex-direction: column;
  }

  .status-list {
    height: 100%;

    overflow: scroll;
  }
</style>

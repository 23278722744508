import type { Account } from '../api/account';
import type { InstanceInfo } from '../api/instance';
import { toasts } from '../toast';
import { getHandle } from '../utils/handle';
import { localStorageWritable } from '../utils/local-storage-writable';
import { revokeToken } from './logout';

export type AccountAuthed = Account & {
  instanceUrl: string;
  instanceInfo: InstanceInfo;
  token: string;
  clientId: string;
  clientSecret: string;
  loginTime: string;
  handle: string;
};

const createAccounts = () => {
  const { subscribe, update } = localStorageWritable<
  Record<string, AccountAuthed>
  >('accounts', {});

  return {
    subscribe,
    /** Adds an account to the store after logging in. */
    addAccount: (newAccount: AccountAuthed) =>
      update((accounts) => ({
        ...accounts,
        [getHandle(newAccount)]: newAccount,
      })),
    /** Logs the user out of an account and revokes their access token. */
    logout: (handle: string) =>
      update((_accounts) => {
        const accounts = { ..._accounts };

        const account = accounts[handle];
        if (!account) {
          return accounts;
        }

        void (async () => {
          try {
            await revokeToken(account);
          } catch (err) {
            if (err instanceof Error) {
              toasts.error(err.message);
            }
            console.error(err);
          }
        })();

        delete accounts[handle];
        return accounts;
      }),
  };
};

/**
 * The accounts store contains all the accounts the user is currently logged in
 * to.
 */
export const accounts = createAccounts();

<script lang="ts">
  import type { HTMLAttributes } from 'svelte/elements';
  import { fade } from 'svelte/transition';
  import { clickOutside } from '../utils/click-outside';
  import Icon from './Icon.svelte';

  type $$Props = HTMLAttributes<HTMLElementTagNameMap['dialog']> & {
    show: boolean;
    title: string;
    allowManualClose?: boolean;
  };

  export let show: $$Props['show'] = false;
  export let title: $$Props['title'];
  /**
   * If the modal can be manually closed with the close button, escape key, or
   * clicking on the backdrop.
   */
  export let allowManualClose: $$Props['allowManualClose'] = true;

  let dialog: HTMLDialogElement;

  const FADE_DURATION = 250;

  $: void (async () => {
    if (!dialog) {
      return;
    }

    if (show) {
      dialog.showModal();
      dialog.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: FADE_DURATION,
        fill: 'both',
      });
    } else {
      await dialog.animate(
        [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
        { duration: FADE_DURATION, fill: 'both' },
      ).finished;
      dialog.close();
    }
  })();

  const handleClose = (e?: Event) => {
    e?.preventDefault();

    if (!allowManualClose) {
      return;
    }

    show = false;
  };
</script>

<dialog
  {...$$restProps}
  transition:fade
  bind:this={dialog}
  on:close={handleClose}
  on:cancel={handleClose}
  use:clickOutside={handleClose}
>
  {#if allowManualClose}
    <button on:click={handleClose} class="close">
      <Icon name="close" alt="Close modal" />
    </button>
  {/if}

  <h2>{title}</h2>
  <slot />
</dialog>

<style lang="scss">
  dialog {
    width: auto;
    max-width: 450px;
    padding: 32px 48px;

    color: var(--col-p-700);
    background-color: var(--col-p-100);
    border: none;
    border-radius: 8px;

    &::backdrop {
      background-color: rgba(0, 0, 0, 50%);
    }
  }

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--col-p-500);
    // p-800
    background-color: rgba(#f2f2f8, 10%);
    border-radius: 8px;
  }

  h2 {
    margin-top: 0;
    line-height: 20px;
  }
</style>

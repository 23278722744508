<script>
  import PostColumn from './PostColumn.svelte';
  import Column from './columns/Column.svelte';
  import AddColumn from './decks/AddColumn.svelte';
  import { selectedDeck } from './decks/decks';
  import AccountSwitcher from './AccountSwitcher.svelte';

  let showAccountSwitcher = false;
</script>

<PostColumn bind:showAccountSwitcher />

{#if $selectedDeck}
  {#each $selectedDeck.cols as col (col.id)}
    <Column {col} />
  {/each}
{/if}

<AddColumn />

{#if showAccountSwitcher}
  <AccountSwitcher bind:showAccountSwitcher />
{/if}

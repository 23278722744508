<script lang="ts">
  import type { Status } from '../../../api/status';
  import Bookmark from './Bookmark.svelte';
  import Boost from './Boost.svelte';
  import Favourite from './Favourite.svelte';

  export let status: Status;
</script>

<section>
  <Boost {status} />
  <Favourite {status} />
  <Bookmark {status} />
</section>

<style lang="scss">
  section {
    margin-top: 12px;

    display: flex;
    align-items: baseline;
    gap: 16px;
  }
</style>

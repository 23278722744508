import { onDestroy } from 'svelte';
import { get, writable } from 'svelte/store';
import type { TimelineStore } from '.';
import type { Status } from '../../../api/status';
import {
  streamTimeline,
  type TimelineStream,
} from '../../../api/timelines/streaming';
import type { BaseGetTimelineArgs } from '../../../api/timelines/timelines';
import type { AccountAuthed } from '../../../auth/accounts';
import { toasts } from '../../../toast';
import { settings } from '../../settings/settings-store';

interface TimelineStoreArgs {
  getTimeline: (
    account: AccountAuthed,
    args: BaseGetTimelineArgs,
  ) => Promise<Status[]>;
  timelineStream?: TimelineStream;
}

export const timelineStoreCreator =
  ({ getTimeline, timelineStream }: TimelineStoreArgs) =>
    (account: AccountAuthed): TimelineStore => {
      const store = writable<Status[]>([]);
      const { subscribe, update, set } = store;

      const wrappedGetTimeline = (args?: BaseGetTimelineArgs) =>
        getTimeline(account, {
          limit: get(settings).infiniteLoading.loadAtATime,
          ...args,
        });

      const initialFetch = async () => {
        set(await wrappedGetTimeline());
      };

      const loadMore = async () => {
        const statuses = get(store);

        if (statuses.length < 1) {
          return;
        }

        const oldestStatusId = statuses[statuses.length - 1].id;

        const newStatuses = await wrappedGetTimeline({
          maxId: oldestStatusId,
        });

        set([...statuses, ...newStatuses]);
      };

      // Connect to timeline streaming API
      if (timelineStream) {
        const closeSocket = streamTimeline(
          account,
          timelineStream,
          {
            onUpdate(newStatus) {
              update((statuses) => [newStatus, ...statuses]);
            },
          },
          {
            onError(event) {
              toasts.error(`Error connecting to timeline streaming (${timelineStream}), check developer console for details.`);
              console.error(event);
            },
          },
        );

        onDestroy(closeSocket);
      }

      return {
        subscribe,
        initialFetch,
        loadMore,
      };
    };

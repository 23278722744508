import { getPublicTimeline } from '../../../api/timelines/timelines';
import { timelineStoreCreator } from './timeline-store-creator';

export const createLocalTimelineStore = timelineStoreCreator({
  getTimeline: (account, args) =>
    getPublicTimeline(account, {
      local: true,
      ...args,
    }),
  timelineStream: 'public:local',
});

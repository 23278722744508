<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import type { SvelteComponent } from '../utils/svelte-component-type';
  import { setHashPartRouterContext } from './hash-part-router-context';

  export let routes: Record<string, SvelteComponent | string>;
  export let currentPath: string | undefined = undefined;

  const hashPart = setHashPartRouterContext();
  let currentComponent: SvelteComponent | undefined;

  const switchToRoute = (route: string) => {
    const routeComponent = routes[route] as
      | string
      | SvelteComponent
      | undefined;

    if (typeof routeComponent === 'string') {
      const newUrl = new URL(window.location.href);
      newUrl.hash += `/${routeComponent}`;
      window.history.replaceState(null, '', newUrl);
      return;
    }

    currentPath = routeComponent ? route : undefined;
    currentComponent = routeComponent ?? undefined;
  };

  const handleHashChange = () => {
    const path = window.location.hash.slice(1);
    const parts = path.split('/');

    if (parts.length <= hashPart) {
      switchToRoute('default');
      return;
    }

    if (parts[hashPart] in routes) {
      switchToRoute(parts[hashPart]);
      return;
    }

    switchToRoute('default');
  };

  onMount(() => {
    window.addEventListener('hashchange', handleHashChange);
    window.addEventListener('popstate', handleHashChange);
    // Run initial component render
    handleHashChange();
  });

  onDestroy(() => {
    window.removeEventListener('hashchange', handleHashChange);
    window.removeEventListener('popstate', handleHashChange);
  });
</script>

<svelte:component this={currentComponent} />

import {
  derived, get, writable, type Writable,
} from 'svelte/store';
import type { Status } from '../../api/status';

/** Local state shared across timeline for statuses. */
interface StatusState {
  /** If a status with a content warning should show it's contents. */
  showContent: boolean;
  /** If the status's media should be shown. */
  showMedia: boolean;
}

/**
 * Identifies a status using the URL of the instance it was fetched with and
 * that instance's ID for that status.
 */
type StatusKey = `${string}-${string}`;

const getStatusKey = (instanceUrl: string, status: Status): StatusKey =>
  `${instanceUrl}-${status.id}`;

const createGlobalStatusState = () => {
  const { subscribe, update, set } = writable<Record<string, StatusState>>({});

  return {
    subscribe,
    set,
    update,
    setStatusValue: <K extends keyof StatusState>(
      statusKey: StatusKey,
      key: K,
      value: StatusState[K],
    ) =>
      update((store) => ({
        ...store,
        [statusKey]: {
          ...store[statusKey],
          [key]: value,
        },
      })),
  };
};

const globalStatusState = createGlobalStatusState();

/**
 * Creates a writable derived store for the chosen {@link StatusState} property
 * for the given status identified by it's instance URL and ID.
 */
const createStatusStatePropertyStore =
  <K extends keyof StatusState>(key: K) =>
    (
      instanceUrl: string,
      status: Status,
      initialValue: StatusState[K],
    ): Writable<StatusState[K]> => {
      const statusKey = getStatusKey(instanceUrl, status);
      const store = derived(
        globalStatusState,
        ($store) => $store[statusKey]?.[key] ?? initialValue,
        initialValue,
      );
      return {
        subscribe: store.subscribe,
        set(value: StatusState[K]) {
          globalStatusState.setStatusValue(statusKey, key, value);
        },
        update(updater) {
          globalStatusState.setStatusValue(statusKey, key, updater(get(store)));
        },
      };
    };

/**
 * A writable store indicating if the specified status should show it's content.
 */
export const statusShowContentStore =
  createStatusStatePropertyStore('showContent');

/**
 * A writable store indicating if the specified status should show it's media.
 */
export const statusShowMediaStore = createStatusStatePropertyStore('showMedia');

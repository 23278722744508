<script lang="ts">
  import App from './app/App.svelte';
  import CompleteLogin from './auth/CompleteLogin.svelte';
  import { currentAccount } from './auth/current-account';
  import LoggedOut from './LoggedOut.svelte';
  import Toaster from './toast/Toaster.svelte';

  $: signedIn = Boolean($currentAccount);
</script>

<CompleteLogin />

{#if signedIn}
  <App />
{:else}
  <LoggedOut />
{/if}

<Toaster />

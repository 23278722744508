<script lang="ts">
  import DragHandle from '../../ui/DragHandle.svelte';

  export let title: string;
  export let subtitle = '';
  export let dragHandle = true;
  export let onHeaderDblClick: ((e: MouseEvent) => void) | undefined =
    undefined;
  export let onInfoClick: ((e: MouseEvent) => void) | undefined = undefined;
</script>

<!-- TODO: remove double click event -->
<header
  on:dblclick={onHeaderDblClick}
  style:padding-left={dragHandle ? '8px' : 'var(--timeline-h-padding)'}
>
  {#if dragHandle}
    <DragHandle />
  {/if}

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- Only has on:click if it's a button element -->
  <svelte:element
    this={Boolean(onInfoClick) ? 'button' : 'div'}
    class="timeline-info"
    on:click={onInfoClick}
  >
    <p class="timeline-handle">
      <slot name="subtitle">
        {#if subtitle}
          {subtitle}
        {:else}
          <!-- Always take up space -->
          <br />
        {/if}
      </slot>
    </p>
    <p class="timeline-name">{title}</p>
  </svelte:element>
</header>

<style lang="scss">
  header {
    margin: 10px 0;
    padding: 0 var(--timeline-h-padding) 0 8px;

    display: flex;
    align-items: center;
    gap: 8px;

    .timeline-info {
      flex-grow: 1;
      padding: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;

      font-size: inherit;
      line-height: inherit;
      color: inherit;
      background: none;

      overflow: hidden;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .timeline-handle {
        margin: 0;
        font-size: 12px;
        margin-top: -4px;
      }

      .timeline-name {
        margin: 0;
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
</style>

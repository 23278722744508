/** Convert a camelCase string to snake_case. */
const camelToSnake = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Convert the given object's keys to snake_case.
 *
 * 🐍
 */
export const snakify = <T extends Record<string, unknown> | object>(obj: T) => {
  const res = {} as Record<string, unknown>;

  for (const [key, val] of Object.entries(obj)) {
    if (typeof val === 'object' && val !== null) {
      res[camelToSnake(key)] = snakify(val);
    } else {
      res[camelToSnake(key)] = val;
    }
  }

  return res;
};

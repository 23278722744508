<script>
  import { settings } from '../settings-store';
</script>

<section>
  <h2>Post Language (not implemented yet)</h2>

  <label for="default-post-language">
    <span>Default post language:</span>
    <select
      id="default-post-language"
      bind:value={$settings.postLanguage.default}
      disabled
    >
      <option value="en">English</option>
    </select>
  </label>
</section>

<script>
  import PostLanguage from './PostLanguage.svelte';
  import PostPrivacy from './PostPrivacy.svelte';
</script>

<section class="settings-section">
  <h1>Posting</h1>

  <PostPrivacy />
  <PostLanguage />
</section>

import { get } from 'svelte/store';
import { decks } from './app/decks/decks';
import { settings } from './app/settings/settings-store';
import { accounts } from './auth';
import { currentAccount } from './auth/current-account';
import Root from './Root.svelte';
import './styles/index.scss';
import { toasts } from './toast';

const app = new Root({
  target: document.body,
});

export default app;

// Expose some stores to window for debugging
window.skbDecks = decks;
window.skbGet = get;
window.skbAccounts = accounts;
window.skbCurrentAccount = currentAccount;
window.skbSettings = settings;
window.skbToasts = toasts;

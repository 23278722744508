import { get } from 'svelte/store';
import { accounts } from '../../auth/accounts';
import {
  createFederatedTimelineStore,
  createHomeTimelineStore,
  createLocalTimelineStore,
} from '../timeline/stores';
import { createBookmarksTimelineSore } from '../timeline/stores/bookmarks';
import { createExternalTimelineStore } from '../timeline/stores/external';
import type { TimelineColumn } from './timeline-column';

const storeCreators = {
  home: createHomeTimelineStore,
  local: createLocalTimelineStore,
  federated: createFederatedTimelineStore,
  bookmarks: createBookmarksTimelineSore,
};

export const createTimelineStore = (column: TimelineColumn) => {
  if (column.type === 'external') {
    return createExternalTimelineStore(column.instanceUrl);
  }

  const storeCreator = storeCreators[column.type];
  const account = get(accounts)[column.accountHandle];
  if (!account) {
    throw new Error(`Account for column ${column.id} not found`);
  }
  return storeCreator(account);
};

<script lang="ts">
  export let size = 32;

  $: borderWidth = size <= 16 ? 2 : 3;
</script>

<div
  style:width={`${size - borderWidth * 2}px`}
  style:height={`${size - borderWidth * 2}px`}
  style:border-width={borderWidth}
  role="progressbar"
  aria-label="Loading"
/>

<style>
  div {
    border-style: solid;
    border-width: 3px;
    border-color: var(--col-p-200);
    border-top-color: var(--col-p-700);
    border-radius: 50%;

    animation: spinner 750ms linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>

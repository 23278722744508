import type { AccountAuthed } from '../auth/accounts';
import { typedFetch } from './typed-fetch';

/**
 * Makes a fetched request with the bearer token for the given account
 * attached.
 *
 * Uses typedFetch to add a type to the response.
 */
export const authedFetch = <Response>(
  account: AccountAuthed,
  ...args: Parameters<typeof typedFetch>
) =>
    typedFetch<Response>(args[0], {
      ...args[1],
      headers: {
        Authorization: `Bearer ${account.token}`,
        ...args[1]?.headers,
      },
    });

/**
 * Make a POST request authorized for the given {@link account}.
 */
export const authedPost = <Response, Body = unknown>(
  account: AccountAuthed,
  url: string,
  body: Body,
) =>
    authedFetch<Response>(account, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

<script lang="ts">
  import { getEditorContext } from './editor-context';

  const editor = getEditorContext();
</script>

<textarea
  name="content"
  id="content"
  placeholder=""
  bind:value={$editor.postContents}
  maxlength={$editor.maxChars}
/>

<style lang="scss">
  textarea {
    min-height: 100px;
    max-height: 768px;
    border-radius: 4px 4px 0 0;

    border: solid 4px transparent;
    transition: border-color 150ms;
    &:focus-visible {
      outline: none;
      border-color: var(--col-p-300);
    }
  }
</style>

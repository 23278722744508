const LOGIN_STATUS_KEY = 'loginStatus';

/**
 * The LoginStatus contains the state for the current in-progress login, so
 * that we can complete the login once the user returns from authenticating
 * through their instance.
 */
export interface LoginStatus {
  time: Date;
  instanceUrl: string;
  clientId: string;
  clientSecret: string;
}

export const setLoginStatus = (data: LoginStatus) => {
  localStorage.setItem(LOGIN_STATUS_KEY, JSON.stringify(data));
};

export const getLoginStatus = () => {
  const data = localStorage.getItem(LOGIN_STATUS_KEY);
  if (data) {
    const parsed = JSON.parse(data) as Omit<LoginStatus, 'time'> & {
      time: string;
    };
    return {
      ...parsed,
      time: new Date(parsed.time),
    };
  }
  return undefined;
};

export const clearLoginStatus = () => localStorage.removeItem(LOGIN_STATUS_KEY);

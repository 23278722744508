import type { Account } from '../api/account';
import type { AccountAuthed } from '../auth/accounts';

export const getHandle = (account: Pick<AccountAuthed, 'instanceUrl' | 'username'>) => {
  const cleanInstanceUrl = new URL(account.instanceUrl).host;
  return `@${account.username}@${cleanInstanceUrl}`;
};

export const getCleanHandle = (account: AccountAuthed) =>
  `@${account.username}@${new URL(account.instanceUrl).host}`;

export const getDisplayName = (account: Account) =>
  account.display_name || account.username;

<script lang="ts">
  import { StatusVisibility } from '../../../api/status';
  import { settings } from '../settings-store';
</script>

<section>
  <h2>Post Privacy</h2>

  <label for="default-post-privacy">
    <span>Default post privacy:</span>
    <select
      id="default-post-privacy"
      bind:value={$settings.postPrivacy.default}
    >
      <option value={StatusVisibility.Public}>Public</option>
      <option value={StatusVisibility.Unlisted}>Unlisted</option>
      <option value={StatusVisibility.Private}>Followers only</option>
      <option value={StatusVisibility.Direct}>Mentioned users only</option>
    </select>
  </label>

  <label for="reset-after-posting">
    <input
      type="checkbox"
      id="reset-after-posting"
      bind:checked={$settings.postPrivacy.resetAfterPosting}
    />
    <span>Reset post privacy back to default after posting</span>
  </label>
</section>
